import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Switch,
  Typography,
} from "@mui/material";
import LinkOffOutlinedIcon from "@mui/icons-material/LinkOffOutlined";
import { ViewingMode } from "utils/viewing-utils";
import { useTranslation } from "react-i18next";
import "./organisation-details-scenarios-list.component.scss";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import Permission from "features/autorisation/domain/models/permission";
import { ExpandMore, LinkOutlined } from "@mui/icons-material";
import Scenario from "features/organisation/models/scenario";
import ScenarioName from "features/scenario/views/scenario-name.component";
import { ReactElement } from "react";
import OrganisationUnitScenarioIcon from "features/residents/residents-settings/views/resident-alarm-scenarios/organisation-unit-scenario-icon";
import { ScenarioType } from "models/scenario-type";
import { useAuth } from "features/authentication/providers/authentication.provider";
import { Controller, useFormContext } from "react-hook-form";
import SettingsRow from "./settings-row.component";
import IntentionOutOfBedIcon from "components/custom-icons/intention-out-of-bed-icon";

interface IProps {
  viewingMode: ViewingMode;
  linkedScenarios: Scenario[] | undefined;
  unlinkedScenarios: Scenario[] | undefined;
  onUnlinkButtonClicked: (selectedScenario: Scenario) => void;
  onRelinkButtonClicked: (selectedScenario: Scenario) => void;
}

function OrganisationDetailsScenariosList(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("organisation");
  const { hasPermission } = useAuth();
  const { control } = useFormContext();
  function linkedScenarioRow(linkedScenario: Scenario): ReactElement {
    return (
      <div key={linkedScenario.id} className="scenario-container">
        <div className="scenario-row">
          <OrganisationUnitScenarioIcon
            scenarioType={linkedScenario.scenarioType}
          />
          <Typography
            variant="subtitle1"
            data-testid={`scenarioTypeTextFor${linkedScenario.scenarioType}`}
          >
            <ScenarioName scenarioType={linkedScenario.scenarioType} />
          </Typography>
        </div>
        {props.viewingMode !== "viewing" && (
          <AutorisationWrapper
            atLeastOnePermissionOf={[Permission.LinkScenario]}
          >
            <Button
              startIcon={<LinkOffOutlinedIcon />}
              onClick={() => props.onUnlinkButtonClicked(linkedScenario)}
              variant="text"
            >
              {t("scenariosList.unlinkButtonText")}
            </Button>
          </AutorisationWrapper>
        )}
      </div>
    );
  }

  return (props.linkedScenarios && props.linkedScenarios.length > 0) ||
    (props.unlinkedScenarios && props.unlinkedScenarios?.length > 0) ? (
    <div className="organisation-details-scenarios-container">
      {props.linkedScenarios?.map((linkedScenario) => {
        if (linkedScenario.scenarioType === ScenarioType.Bed) {
          return (
            <Accordion key={linkedScenario.id} disableGutters>
              <AccordionSummary expandIcon={<ExpandMore />} sx={{ padding: 0 }}>
                {linkedScenarioRow(linkedScenario)}
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                <Controller
                  name="intentionOutOfBedEnabled"
                  control={control}
                  render={({ field }) => (
                    <SettingsRow
                      icon={<IntentionOutOfBedIcon />}
                      text={t("scenariosList.intentionOutOfBed")}
                      formField={
                        <Switch
                          {...field}
                          type="checkbox"
                          value={field.value}
                          checked={field.value}
                          // prettier-ignore
                          // @ts-ignore
                          inputProps={{ "data-testid": "intentionOutOfBedEnabledSwitch" }}
                          disabled={
                            props.viewingMode === "viewing" ||
                            !hasPermission(Permission.LinkScenario)
                          }
                        />
                      }
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
          );
        }
        return linkedScenarioRow(linkedScenario);
      })}
      {props.unlinkedScenarios?.map((unlinkedScenario) => {
        return (
          <div key={unlinkedScenario.id} className="scenario-container">
            <div className="scenario-row">
              <OrganisationUnitScenarioIcon
                scenarioType={unlinkedScenario.scenarioType}
              />
              <Typography
                variant="subtitle1"
                className="unlinked-scenario"
                data-testid={`scenarioTypeTextFor${unlinkedScenario.scenarioType}`}
              >
                <ScenarioName scenarioType={unlinkedScenario.scenarioType} />
              </Typography>
            </div>
            {props.viewingMode !== "viewing" && (
              <AutorisationWrapper
                atLeastOnePermissionOf={[Permission.LinkScenario]}
              >
                <Button
                  startIcon={<LinkOutlined />}
                  onClick={() => props.onRelinkButtonClicked(unlinkedScenario)}
                  variant="text"
                >
                  {t("scenariosList.relinkButtonText")}
                </Button>
              </AutorisationWrapper>
            )}
          </div>
        );
      })}
    </div>
  ) : (
    <Typography variant="subtitle1">
      {t("scenariosList.noScenariosLinked")}
    </Typography>
  );
}

export default OrganisationDetailsScenariosList;
