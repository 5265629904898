export enum MonitoringType {
  Offline = "Offline",
  Online = "Online",
  BatteryCritical = "BatteryCritical",
  BatteryLow = "BatteryLow",
  OutDated = "OutDated",
  ExternalSystemOffline = "ExternalSystemOffline",
  ExternalSystemOnline = "ExternalSystemOnline",
  All = "All",
  NotMonitored = "NotMonitored",
  PassiveExternalSystemOffline = "PassiveExternalSystemOffline",
}
