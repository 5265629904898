import { useState, useEffect } from "react";
import BroadCastOnPersonalOutlinedIcon from "@mui/icons-material/BroadcastOnPersonalOutlined";
import BadgeOutlinedIcon from "@mui/icons-material/BadgeOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  DashboardOutlined,
  GroupAddOutlined,
  HistoryRounded,
  PersonPinOutlined,
} from "@mui/icons-material";
import IMenuItemGroup from "features/menu/views/menu-item-group";
import routes from "features/routing/routes";

function useMenuItems() {
  const [menuItems, setMenuItems] = useState<IMenuItemGroup[]>([]);

  useEffect(() => {
    const defaultMenuItems: IMenuItemGroup[] = [
      {
        key: "keyUserMenuItems",
        menuItems: [
          {
            icon: <PersonPinOutlined />,
            titleTranslationKey: "residentsSettings",
            atLeastOnePermissionOf:
              routes.residentsSettings.atLeastOnePermissionOf,
            route: routes.residentsSettings.path,
          },
          {
            icon: <GroupAddOutlined />,
            titleTranslationKey: "TemporaryAccess",
            atLeastOnePermissionOf:
              routes.temporaryAccess.atLeastOnePermissionOf,
            route: routes.temporaryAccess.path,
          },
          {
            icon: <HistoryRounded />,
            titleTranslationKey: "history",
            atLeastOnePermissionOf: routes.history.atLeastOnePermissionOf,
            route: routes.history.path,
          },
          {
            icon: <DashboardOutlined />,
            titleTranslationKey: "dashboard",
            atLeastOnePermissionOf: routes.dashboard.atLeastOnePermissionOf,
            route: routes.dashboard.path,
          },
        ],
      },
      {
        key: "adminMenuItems",
        menuItems: [
          {
            icon: <BadgeOutlinedIcon />,
            titleTranslationKey: "roles",
            atLeastOnePermissionOf: routes.roles.atLeastOnePermissionOf,
            route: routes.roles.path,
          },
          {
            icon: <AccountTreeOutlinedIcon />,
            titleTranslationKey: "organisation",
            atLeastOnePermissionOf: routes.organisation.atLeastOnePermissionOf,
            route: routes.organisation.path,
          },
          {
            icon: <SettingsOutlinedIcon />,
            titleTranslationKey: "settings",
            atLeastOnePermissionOf: routes.settings.atLeastOnePermissionOf,
            route: routes.settings.path,
          },
          {
            icon: <BroadCastOnPersonalOutlinedIcon />,
            titleTranslationKey: "hardware",
            atLeastOnePermissionOf: routes.hardware.atLeastOnePermissionOf,
            route: routes.hardware.path,
          },
        ],
      },
      {
        key: "tenantMenuItems",
        menuItems: [
          {
            icon: <BusinessRoundedIcon />,
            titleTranslationKey: "tenants",
            atLeastOnePermissionOf: routes.tenants.atLeastOnePermissionOf,
            route: routes.tenants.path,
            showForTenantManagerOnly: routes.tenants.showForTenantManagerOnly,
          },
        ],
      },
    ];

    setMenuItems(defaultMenuItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setMenuItems]);

  return menuItems;
}

export default useMenuItems;
