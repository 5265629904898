import ExternalSystem from "../domain/models/external-system";
import {
  useCreateSystemMutation,
  useDeleteSystemMutation,
  useUpdateSystemMutation,
} from "../domain/reducers/external-system.reducer";
import { useEffect, useState } from "react";
import { ViewingMode } from "utils/viewing-utils";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import {
  setShouldShowConfirmation,
  showConfirmationPopup,
} from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { ExternalSystemType } from "../domain/models/external-system-type";
import { UseFormReturn, useForm } from "react-hook-form";

export interface IExternalSystemDetailsHook {
  viewingMode: ViewingMode;
  mutationIsLoading: boolean;

  currentSelectedExternalSystem: ExternalSystem | undefined;
  changeCurrentSelectedExternalSystem: (
    externalSystem: ExternalSystem | undefined,
  ) => void;

  deleteExternalSystem: (externalSystem: ExternalSystem) => void;

  submitExternalSystem: (externalSystem: ExternalSystem) => void;

  cancelMutation: () => void;

  isDetailsOpen: boolean;
  openDetailsInCreationMode: () => void;
  openDetailsInViewingMode: (externalSystem: ExternalSystem) => void;
  openDetailsInEditingMode: (externalSystem: ExternalSystem) => void;
  closeDetails: (isFormStateDirty: boolean) => void;
  resetOnSystemTypeUpdate: (type: ExternalSystemType) => void;

  isDeleteErrorPopupOpen: boolean;
  closeDeleteErrorPopup: () => void;

  isDeleteConfirmationPopupOpen: boolean;
  closeDeleteConfirmationPopup: () => void;
  deleteConfirmationAction: () => void;

  isIpAddressPopupOpen: boolean;
  closeIpAddressPopup: () => void;
  ipAddressConfirmationAction: () => void;

  form: UseFormReturn<Partial<ExternalSystem>, any>;
}

const useExternalSystemDetails = (): IExternalSystemDetailsHook => {
  const dispatch = useDispatch();

  const form = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] =
    useState<boolean>(false);
  const [isDeleteErrorPopupOpen, setIsDeleteErrorPopupOpen] =
    useState<boolean>(false);
  const [deleteConfirmationAction, setDeleteConfirmationAction] = useState(
    () => () => {},
  );
  const [isIpAddressPopupOpen, setIsIpAddressPopupOpen] =
    useState<boolean>(false);
  const [ipAddressConfirmationAction, setIpAddressConfirmationAction] =
    useState(() => () => {});
  const [mutationIsLoading, setMutationIsLoading] = useState<boolean>(false);
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [viewingMode, setViewingMode] = useState<ViewingMode>("viewing");
  const [currentSelectedExternalSystem, setCurrentSelectedExternalSystem] =
    useState<ExternalSystem>();

  const [
    deleteSystem,
    {
      isSuccess: deleteExternalSystemIsSuccess,
      isLoading: deleteExternalSystemIsLoading,
      isError: deleteExternalSystemIsError,
      error: deleteExternalSystemError,
    },
  ] = useDeleteSystemMutation();

  const [
    createExternalSystem,
    {
      isLoading: createExternalSystemIsLoading,
      isSuccess: createExternalSystemIsSuccess,
      isError: createExternalSystemIsError,
      error: createExternalSystemError,
      data: createExternalSystemData,
    },
  ] = useCreateSystemMutation();

  const [
    updateExternalSystem,
    {
      isLoading: updateExternalSystemIsLoading,
      isSuccess: updateExternalSystemIsSuccess,
      isError: updateExternalSystemIsError,
      error: updateExternalSystemError,
      data: updateExternalSystemData,
    },
  ] = useUpdateSystemMutation();

  useEffect(() => {
    form.reset({
      name: currentSelectedExternalSystem?.name ?? "",
      ipAddress: currentSelectedExternalSystem?.ipAddress,
      note: currentSelectedExternalSystem?.note ?? "",
      type: currentSelectedExternalSystem?.type ?? ExternalSystemType.Viewpoint,
      organisationUnitId: currentSelectedExternalSystem?.organisationUnitId,
      shouldCreateDevicesAutomatically:
        currentSelectedExternalSystem?.shouldCreateDevicesAutomatically ??
        false,
      offlinePortalUsername:
        currentSelectedExternalSystem?.offlinePortalUsername,
      offlinePortalPassword:
        currentSelectedExternalSystem?.offlinePortalPassword,
      childExternalSystems:
        currentSelectedExternalSystem?.childExternalSystems ?? [],
      kadexIpAddress: currentSelectedExternalSystem?.kadexIpAddress,
      kadexUsername: currentSelectedExternalSystem?.kadexUsername,
      kadexPassword: currentSelectedExternalSystem?.kadexPassword,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSelectedExternalSystem]);

  useEffect(() => {
    dispatch(
      setShouldShowConfirmation({
        shouldShowConfirmation: form.formState.isDirty,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty]);

  useEffect(() => {
    if (createExternalSystemIsSuccess && createExternalSystemData) {
      setCurrentSelectedExternalSystem(createExternalSystemData);
      setViewingMode("viewing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createExternalSystemIsSuccess]);

  useEffect(() => {
    if (updateExternalSystemIsSuccess && updateExternalSystemData) {
      setCurrentSelectedExternalSystem(updateExternalSystemData);
      setViewingMode("viewing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateExternalSystemIsSuccess]);

  useEffect(() => {
    if (createExternalSystemIsError && createExternalSystemError) {
      dispatch(
        setErrorMessage({
          error: createExternalSystemError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createExternalSystemIsError]);

  useEffect(() => {
    if (updateExternalSystemIsError && updateExternalSystemError) {
      dispatch(
        setErrorMessage({
          error: updateExternalSystemError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateExternalSystemIsError]);

  useEffect(() => {
    if (deleteExternalSystemIsError && deleteExternalSystemError) {
      dispatch(
        setErrorMessage({
          error: deleteExternalSystemError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteExternalSystemIsError]);

  useEffect(() => {
    if (deleteExternalSystemIsSuccess) {
      setIsDeleteConfirmationPopupOpen(false);
      setIsDetailsOpen(false);
    }
  }, [deleteExternalSystemIsSuccess]);

  useEffect(() => {
    setMutationIsLoading(
      updateExternalSystemIsLoading ||
        createExternalSystemIsLoading ||
        deleteExternalSystemIsLoading,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    updateExternalSystemIsLoading,
    createExternalSystemIsLoading,
    deleteExternalSystemIsLoading,
  ]);

  const changeCurrentSelectedExternalSystem = (
    externalSystem: ExternalSystem | undefined,
  ) => {
    setCurrentSelectedExternalSystem(externalSystem);
  };

  const submitExternalSystem = (externalSystem: ExternalSystem) => {
    if (
      (currentSelectedExternalSystem?.type === ExternalSystemType.IoTEdge ||
        currentSelectedExternalSystem?.type ===
          ExternalSystemType.IoTEdgeCluster) &&
      currentSelectedExternalSystem?.id &&
      currentSelectedExternalSystem?.ipAddress !==
        externalSystem.ipAddress?.trim()
    ) {
      setIsIpAddressPopupOpen(true);
      setIpAddressConfirmationAction(() => () => {
        saveExternalSystem(externalSystem);
        setIsIpAddressPopupOpen(false);
      });

      return;
    }

    saveExternalSystem(externalSystem);
  };

  const saveExternalSystem = (externalSystem: ExternalSystem) => {
    viewingMode === "creation"
      ? createExternalSystem(externalSystem)
      : updateExternalSystem(externalSystem);
  };

  const deleteExternalSystem = (externalSystem: ExternalSystem) => {
    if (externalSystem.devices?.some((device) => !!device.organisationUnitId)) {
      setIsDeleteErrorPopupOpen(true);

      return;
    }

    setIsDeleteConfirmationPopupOpen(true);
    setDeleteConfirmationAction(
      () => () => confirmDeleteSystem(externalSystem),
    );
  };

  const openDetailsInCreationMode = () => {
    setCurrentSelectedExternalSystem(undefined);
    setIsDetailsOpen(true);
    setViewingMode("creation");
  };

  const openDetailsInEditingMode = (
    externalSystem: ExternalSystem | undefined,
  ) => {
    setCurrentSelectedExternalSystem(externalSystem);
    setIsDetailsOpen(true);
    setViewingMode("editing");
  };

  const openDetailsInViewingMode = (
    externalSystem: ExternalSystem | undefined,
  ) => {
    setCurrentSelectedExternalSystem(externalSystem);
    setIsDetailsOpen(true);
    setViewingMode("viewing");
  };

  const closeDetails = (isFormStateDirty: boolean) => {
    if (
      (viewingMode === "creation" || viewingMode === "editing") &&
      isFormStateDirty
    ) {
      dispatch(
        showConfirmationPopup({
          showInstantly: true,
          confirmActionNextAction: () => {
            setIsDetailsOpen(false);
            setCurrentSelectedExternalSystem(undefined);
          },
        }),
      );
    } else {
      setIsDetailsOpen(false);
      setCurrentSelectedExternalSystem(undefined);
    }
  };

  const cancelMutation = () => {
    if (viewingMode === "creation") {
      closeDetails(false);
    } else if (viewingMode === "editing") {
      setViewingMode("viewing");
    }

    form.reset();
  };

  const closeDeleteConfirmationPopup = () => {
    setIsDeleteConfirmationPopupOpen(false);
  };

  const closeDeleteErrorPopup = () => {
    setIsDeleteErrorPopupOpen(false);
  };

  const closeIpAddressPopup = () => {
    setIsIpAddressPopupOpen(false);
  };

  async function confirmDeleteSystem(
    externalSystem: ExternalSystem,
  ): Promise<void> {
    deleteSystem(externalSystem.id!);
    setDeleteConfirmationAction(() => () => {});
  }

  const resetOnSystemTypeUpdate = (type: ExternalSystemType) => {
    form.reset({
      name: "",
      ipAddress: "",
      note: "",
      type,
      organisationUnitId: undefined,
      shouldCreateDevicesAutomatically: false,
      offlinePortalUsername: "",
      offlinePortalPassword: "",
      childExternalSystems: [],
      kadexIpAddress: "",
      kadexUsername: "",
      kadexPassword: "",
    });
  };

  return {
    viewingMode,
    mutationIsLoading,

    currentSelectedExternalSystem,
    changeCurrentSelectedExternalSystem,

    deleteExternalSystem,

    submitExternalSystem,

    cancelMutation,

    resetOnSystemTypeUpdate,

    isDetailsOpen,
    openDetailsInCreationMode,
    openDetailsInEditingMode,
    openDetailsInViewingMode,
    closeDetails,

    isDeleteErrorPopupOpen,
    closeDeleteErrorPopup,

    isDeleteConfirmationPopupOpen,
    closeDeleteConfirmationPopup,
    deleteConfirmationAction,

    isIpAddressPopupOpen,
    closeIpAddressPopup,
    ipAddressConfirmationAction,

    form,
  };
};

export default useExternalSystemDetails;
