import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import { MalfunctionNotificationResponse } from "../models/malfunction-notification-response";
import MalfunctionNotificationCommand from "../models/malfunction-notification-command";

const baseUrl = "/api/v1/Settings";

export const malfunctionNotificationApi = createApi({
  reducerPath: "malfunctionNotifications",
  baseQuery,
  tagTypes: ["MalfunctionNotifications"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    upsertMalfunctionNotifications: build.mutation<
      MalfunctionNotificationResponse,
      MalfunctionNotificationCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/UpsertMalfunctionNotifications`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["MalfunctionNotifications"],
    }),
    readMalfunctionNotifications: build.query<
      MalfunctionNotificationResponse,
      void
    >({
      query: () => ({
        url: `${baseUrl}/ReadMalfunctionNotifications`,
        method: "GET",
      }),
      providesTags: ["MalfunctionNotifications"],
    }),
  }),
});

export const {
  useReadMalfunctionNotificationsQuery,
  useUpsertMalfunctionNotificationsMutation,
} = malfunctionNotificationApi;
