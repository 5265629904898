import React, { ReactElement, ReactNode } from "react";
import useExternalSystems, {
  IExternalSystemsHook,
} from "./external-systems-hook";

const ExternalSystemContext = React.createContext<IExternalSystemsHook>(
  {} as never,
);

interface IProps {
  children: ReactNode;
}

export const ExternalSystemsProvider = (
  props: Readonly<IProps>,
): ReactElement => {
  return (
    <ExternalSystemContext.Provider value={useExternalSystems()}>
      {props.children}
    </ExternalSystemContext.Provider>
  );
};

export const useExternalSystemsContextProvider = (): IExternalSystemsHook =>
  React.useContext<IExternalSystemsHook>(ExternalSystemContext);
