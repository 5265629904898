import { useEffect, useState } from "react";
import { ReadTemporaryAccessQuery } from "../domain/models/read-temporay-access-query";
import { useSessionStorage } from "usehooks-ts";
import useTableHook, { ITableHook } from "hooks/table-hook";
import TemporaryAccess from "../domain/models/temporary-access";
import { FilterValueType } from "../domain/models/temporary-access-filter-value";
import TemporaryAccessFilter from "../domain/models/temporary-access-filter";
import { DateFilters } from "components/filter-chip/models/date-filters";
import moment, { Moment } from "moment";

export interface ITemporaryAccessFilterHook {
  activeFilters: TemporaryAccessFilter[];
  searchBarValue: string;

  submitSearch: (query: string) => void;
  selectOption: (filterValueType: FilterValueType, keys: string[]) => void;
  clearFilters: () => void;
  dateFilter: DateFilters;
  setDateFilter: (dateFilter: DateFilters) => void;

  readTemporaryAccessQuery: ReadTemporaryAccessQuery;

  tableHook: ITableHook<TemporaryAccess>;
}

const useTemporaryAccessFilters = (): ITemporaryAccessFilterHook => {
  const [activeFilters, setActiveFilters] = useState<TemporaryAccessFilter[]>(
    [],
  );

  const defaultDateFilters = {
    dateFrom: null,
    dateTo: null,
  };

  const [dateFilterFromSessionStorage, setDateFilterFromSessionStorage] =
    useSessionStorage<DateFilters>(
      "temporary-access-date-filter",
      defaultDateFilters,
    );

  const tableHook = useTableHook<TemporaryAccess>();

  const [
    temporaryAccessFiltersFromSessionStorage,
    setTemporaryAccessFiltersFromSessionStorage,
  ] = useSessionStorage<TemporaryAccessFilter[]>(
    "temporary-access-filters",
    [],
  );

  const [searchBarValueFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>("search-bar-value-temporary-access-filters", "");

  const [readTemporaryAccessQuery, setReadTemporaryAccessQuery] =
    useState<ReadTemporaryAccessQuery>({
      filters: temporaryAccessFiltersFromSessionStorage,
      pagination: tableHook.currentPagination[0],
      searchQuery: searchBarValueFromSessionStorage,
      from: getDate(dateFilterFromSessionStorage.dateFrom),
      to: getDate(dateFilterFromSessionStorage.dateTo),
    } as ReadTemporaryAccessQuery);

  useEffect(() => {
    buildAndSaveQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tableHook.currentPage,
    tableHook.currentRowsPerPage,
    temporaryAccessFiltersFromSessionStorage,
    searchBarValueFromSessionStorage,
  ]);

  const submitSearch = (query: string) => {
    setSearchBarFromSessionStorage(query);
    tableHook.resetPaging();
  };

  const selectOption = (filterValueType: FilterValueType, keys: string[]) => {
    let updatedFiltersList = [...readTemporaryAccessQuery.filters];
    if (
      updatedFiltersList.findIndex(
        (x) => x.filterValueType === filterValueType,
      ) !== -1
    ) {
      updatedFiltersList[
        updatedFiltersList.findIndex(
          (x) => x.filterValueType === filterValueType,
        )
      ] = {
        filterValueType: filterValueType,
        values: keys,
      };
    } else {
      updatedFiltersList.push({
        filterValueType: filterValueType,
        values: keys,
      });
    }

    updatedFiltersList = updatedFiltersList.filter((x) => x.values.length > 0);

    setTemporaryAccessFiltersFromSessionStorage(updatedFiltersList);

    tableHook.resetPaging();
  };

  const clearFilters = () => {
    setActiveFilters([]);
    setTemporaryAccessFiltersFromSessionStorage([]);
    setDateFilterFromSessionStorage(defaultDateFilters);
    setReadTemporaryAccessQuery((prevState) => ({
      ...prevState,
      filters: [],
    }));

    tableHook.resetPaging();
  };

  const setDateFilter = (dateFilter: DateFilters) => {
    setDateFilterFromSessionStorage(dateFilter);
    tableHook.resetPaging();
  };

  function getDate(date: Moment | null | undefined): Date | undefined {
    if (date) {
      return new Date(moment(date).format("YYYY-MM-DD"));
    }
    return undefined;
  }

  function buildAndSaveQuery() {
    let from = getDate(dateFilterFromSessionStorage.dateFrom);
    let to = getDate(dateFilterFromSessionStorage.dateTo);

    setActiveFilters(temporaryAccessFiltersFromSessionStorage);
    setReadTemporaryAccessQuery((prevState) => ({
      ...prevState,
      pagination: tableHook.currentPagination[0],
      filters: temporaryAccessFiltersFromSessionStorage,
      searchQuery: searchBarValueFromSessionStorage,
      from: from,
      to: to,
    }));
  }

  return {
    activeFilters,
    searchBarValue: searchBarValueFromSessionStorage,

    selectOption,
    submitSearch,
    clearFilters,
    dateFilter: dateFilterFromSessionStorage,
    setDateFilter,

    readTemporaryAccessQuery,
    tableHook,
  };
};

export default useTemporaryAccessFilters;
