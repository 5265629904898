import { Typography } from "@mui/material";
import "./alarm-card.component.scss";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { AlarmPriority } from "features/history/domain/models/alarm-priority";
import AlarmIcon from "components/alarm-icon/alarm-icon.component";
import { AlarmStatus } from "features/history/domain/models/alarm-status";
import Constants from "style/constants";
import EmergencyAlarmAcceptedByUsers from "features/history/domain/models/emergency-alarm-accepted-by-user";
import { getAlarmTimeForAlarmCard } from "utils/date-utils";

interface IProps {
  priority: AlarmPriority;
  status: AlarmStatus;
  type: string;
  translatedType: string;
  nearbyText?: string;
  message?: string;
  statusLastChangedAt?: string;
  reportedByFullName: string;
  acceptedByUsers: EmergencyAlarmAcceptedByUsers[];
}

function EmergencyAlarmCard(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("alarmCard");

  function getPriorityColorClassName(): string {
    switch (props.priority) {
      case AlarmPriority.Highest:
        return "highestPriority";
      case AlarmPriority.High:
        return "highPriority";
      case AlarmPriority.Medium:
        return "mediumPriority";
      default:
        return "defaultPriority";
    }
  }

  return (
    <div
      className={`alarm-card-container ${getPriorityColorClassName()} status-${
        props.status
      }`}
    >
      <div className="alarm-card-content">
        <div className={`content-names-row status-${props.status}`}>
          <div className="notification-icon-background">
            <AlarmIcon type={props.type} />
          </div>
          <div className="content-names">
            <Typography variant="body1">{props.reportedByFullName}</Typography>
          </div>
        </div>
        <div className={`content-information-row status-${props.status}`}>
          <Typography variant="subtitle1">{props.translatedType}</Typography>
          <Typography variant="subtitle1">
            {getAlarmTimeForAlarmCard(
              props.statusLastChangedAt,
              t("dateDisplayYesterday"),
            )}
          </Typography>
        </div>

        {props.message && (
          <div className={`content-information-row status-${props.status}`}>
            <Typography variant="subtitle1">{props.message}</Typography>
          </div>
        )}

        {props.nearbyText && (
          <div
            className={`content-nearby-information-row status-${props.status}`}
          >
            <Typography variant="subtitle1">{t("nearby")}</Typography>
            <Typography variant="body1">{props.nearbyText}</Typography>
          </div>
        )}

        {props.status !== AlarmStatus.Reported &&
          props.acceptedByUsers.length > 0 && (
            <>
              <Typography variant="h6">{t("acceptedBy")}</Typography>
              <div className="accepted-by-users-container">
                {props.acceptedByUsers.map((user) => (
                  <div
                    className="status-container"
                    key={`accepted-by-user-${user.id}`}
                  >
                    <div className="avatar-container">
                      <Typography
                        variant="overline"
                        sx={{ color: Constants.Colors.onPrimaryContainer }}
                        className="name"
                      >
                        {`${user.userFullName
                          ?.split(" ")
                          .shift()
                          ?.charAt(0)}${user.userFullName
                          ?.split(" ")
                          .pop()
                          ?.charAt(0)}`}
                      </Typography>
                    </div>
                    <Typography
                      variant="overline"
                      sx={{ color: Constants.Colors.onSurfaceVariant }}
                      className="name"
                    >
                      {user.userFullName}
                    </Typography>
                  </div>
                ))}
              </div>
            </>
          )}
      </div>
    </div>
  );
}
export default EmergencyAlarmCard;
