import { AccountTreeOutlined } from "@mui/icons-material";
import PageHeader from "components/page-header/page-header";
import SettingsNavigation from "features/settings/views/settings-navigation";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useEmergencyAlarmSettingsContextProvider } from "../context/emergency-alarm-settings-provider";
import EmergencyAlarmSettingDetails from "./emergency-alarm-settings-details/emergency-alarm-setting-details.component";
import "./emergency-alarm-settings.scss";
import Table, { IRowItem } from "components/table/table";
import { ColumnDefinition } from "components/table/columnDefinition";
import EmergencyAlarmSetting from "../domain/models/emergency-alarm-setting";
import AlarmSettingIndicator from "./alarm-setting-indicator";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import OutlineToggleButton from "components/outline-toggle-button/outline-toggle-button";

function EmergencyAlarmSettings(): ReactElement {
  const { t } = useTranslation("emergencyAlarm");
  const {
    organisationEmergencyAlarmSettings,
    defaultTemplateSetting,
    emergencyAlarmSettingsDetailsHook,
    emergencyAlarmSettingsIsLoading,
  } = useEmergencyAlarmSettingsContextProvider();

  const columns: ColumnDefinition<
    EmergencyAlarmSetting,
    keyof EmergencyAlarmSetting
  >[] = [
    {
      key: "name",
      label: t("table.columns.location"),
    },
    {
      key: "isDefaultTemplateSetting",
      label: t("table.columns.settings"),
      renderCustomContentProvider: (settings) => (
        <AlarmSettingIndicator isDefault={settings.isDefaultTemplateSetting} />
      ),
      disableSort: true,
    },
  ];

  const hasNoOrganisationId =
    emergencyAlarmSettingsDetailsHook.currentSelectedEmergencyAlarmSettings &&
    !emergencyAlarmSettingsDetailsHook.currentSelectedEmergencyAlarmSettings
      .organisationUnitId;

  const selectedItem = organisationEmergencyAlarmSettings?.find(
    (setting) =>
      setting.organisationUnitId ===
      emergencyAlarmSettingsDetailsHook.currentSelectedEmergencyAlarmSettings
        ?.organisationUnitId,
  );

  return (
    <>
      <PageHeader
        title={t("pageHeaderTitle")}
        navigationComponent={<SettingsNavigation />}
      />
      <div className="emergency-alarm-settings-container">
        <div className="left-content">
          <OutlineToggleButton
            icon={<AccountTreeOutlined />}
            label={t("organisationSettings")}
            isSelected={hasNoOrganisationId}
            onClick={() => {
              emergencyAlarmSettingsDetailsHook.selectSettingDetails(
                defaultTemplateSetting!,
              );
            }}
          ></OutlineToggleButton>
          {emergencyAlarmSettingsIsLoading && <LoadingIndicator />}
          {organisationEmergencyAlarmSettings &&
            organisationEmergencyAlarmSettings?.length > 0 && (
              <Table
                data={organisationEmergencyAlarmSettings.map<
                  IRowItem<EmergencyAlarmSetting>
                >((setting) => ({ data: setting }))}
                columns={columns}
                currentItem={selectedItem}
                onItemClick={(setting: EmergencyAlarmSetting) =>
                  emergencyAlarmSettingsDetailsHook.selectSettingDetails(
                    setting,
                  )
                }
                rowIdentifier={(item: EmergencyAlarmSetting) => item.id}
                useBuiltInSorter={true}
              />
            )}
        </div>
        <EmergencyAlarmSettingDetails />
      </div>
    </>
  );
}

export default EmergencyAlarmSettings;
