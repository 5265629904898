import PermissionsPermission from "./permissions-permission";

enum Permission {
  CreateOrganisationUnit = "CreateOrganisationUnit",
  ReadOrganisationUnit = "ReadOrganisationUnit",
  UpdateOrganisationUnit = "UpdateOrganisationUnit",
  DeleteOrganisationUnit = "DeleteOrganisationUnit",

  CreateRole = "CreateRole",
  ReadRole = "ReadRole",
  UpdateRole = "UpdateRole",
  DeleteRole = "DeleteRole",

  CreateExternalSystem = "CreateExternalSystem",
  ReadExternalSystem = "ReadExternalSystem",
  UpdateExternalSystem = "UpdateExternalSystem",
  DeleteExternalSystem = "DeleteExternalSystem",

  CreateDevice = "CreateDevice",
  ReadDevice = "ReadDevice",
  UpdateDevice = "UpdateDevice",
  DeleteDevice = "DeleteDevice",
  LinkDevice = "LinkDevice",

  AppAccess = "AppAccess",
  ReadOverview = "ReadOverview",

  LinkScenario = "LinkScenario",

  ReadResidentsSettings = "ReadResidentsSettings",
  UpdateResidentsSettings = "UpdateResidentsSettings",

  ReadHistory = "ReadHistory",
  ReadHistoryDetails = "ReadHistoryDetails",

  DashboardAlarms = "DashboardAlarms",
  DashboardResidents = "DashboardResidents",

  ReadTenant = "ReadTenant",
  UpdateTenant = "UpdateTenant",

  MuteApp = "MuteApp",
  UserPreferences = "UserPreferences",

  ManageTemporaryAccess = "ManageTemporaryAccess",
  RequestTemporaryChannelAccess = "RequestTemporaryChannelAccess",

  ReadFaults = "ReadFaults",
  ReadFaultsOnUnlinkedDevices = "ReadFaultsOnUnlinkedDevices",

  SettingsEmergencyAlarm = "SettingsEmergencyAlarm",
  SettingsMalfunctionNotifications = "SettingsMalfunctionNotifications",
  SettingsExplanationLink = "SettingsExplanationLink",
  SendTestEvent = "SendTestEvent",

  CentralSupportPortalAccess = "CentralSupportPortalAccess",
}

export function mapPermissionsToRecord(
  values?: Array<PermissionsPermission>,
): Record<Permission, boolean> {
  let map = new Map();
  for (let value of Object.values(Permission)) {
    map.set(
      value,
      values?.find(
        (permissionsPermission) => permissionsPermission.permission === value,
      )?.isPermissionEnabled ?? false,
    );
  }
  return Object.fromEntries(map) as Record<Permission, boolean>;
}

export function mapPermissionsToArray(
  permissions: Record<Permission, boolean>,
): Array<PermissionsPermission> {
  return Object.entries(permissions).map(
    ([permission, isPermissionEnabled]) => ({
      permission: permission as Permission,
      isPermissionEnabled,
    }),
  );
}

export default Permission;
