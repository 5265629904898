export default interface Tenant {
  realmName: string;
  displayName: string;
  note: string;
  externalSystemMonitorings: Monitoring[];
  devicesMonitorings: Monitoring[];
}

export interface Monitoring {
  type: TenantMonitoringType;
  count: number;
}

export enum TenantMonitoringType {
  Unknown = "Uknown",
  Offline = "Offline",
  BatteryCritical = "BatteryCritical",
  BatteryLow = "BatteryLow",
  OutDated = "OutDated",
  Online = "Online",
  NotMonitored = "NotMonitored",
  PassiveExternalSystemOffline = "PassiveExternalSystemOffline",
}
