import { useExternalSystemsContextProvider } from "../context/external-systems-provider";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useGenerateSecretsMutation } from "../domain/reducers/external-system.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import ExternalSystem from "../domain/models/external-system";

export interface IExternalSystemSettingsIoTEdgeClusterClientHook {
  deviceId: string | undefined;
  ioTEdgeConnectionString: string;
  generateIoTEdgeConnectionStringIsLoading: boolean;
  isGenerateConfirmationOpen: boolean;
  defaultIoTEdgeConnectionString: string;

  generateIoTEdgeClusterDeviceConnectionString: () => void;
  submitGenerateIoTEdgeClusterDeviceConnectionString: () => void;
  closeGenerateConfirmationPopup: () => void;
}

const useExternalSystemSettingsIoTEdgeClusterClient = (
  childExternalSystem: ExternalSystem | undefined,
): IExternalSystemSettingsIoTEdgeClusterClientHook => {
  const dispatch = useDispatch();

  const { externalSystemDetailsHook: detailView } =
    useExternalSystemsContextProvider();

  const defaultIoTEdgeConnectionString = "********";

  const [isGenerateConfirmationOpen, setIsGenerateConfirmationOpen] =
    useState(false);
  const [deviceId, setDeviceId] = useState(childExternalSystem?.deviceId);
  const [ioTEdgeConnectionString, setIoTEdgeConnectionString] = useState(
    defaultIoTEdgeConnectionString,
  );

  const [
    generateSecrets,
    {
      isLoading: generateSecretIsLoading,
      isError: generateSecretIsError,
      error: generateSecretError,
      isSuccess: generateSecretIsSuccess,
      data: generateSecretData,
    },
  ] = useGenerateSecretsMutation();

  useEffect(() => {
    if (generateSecretIsError && generateSecretError) {
      dispatch(
        setErrorMessage({
          error: generateSecretError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateSecretIsError]);

  useEffect(() => {
    if (generateSecretData) {
      setDeviceId(generateSecretData.deviceId);
      setIoTEdgeConnectionString(
        generateSecretData.ioTEdgeConnectionString ??
          defaultIoTEdgeConnectionString,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateSecretIsSuccess]);

  useEffect(() => {
    setDeviceId(childExternalSystem?.deviceId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailView.currentSelectedExternalSystem]);

  const generateIoTEdgeConnectionString = () => {
    if (deviceId && deviceId.length > 0) {
      setIsGenerateConfirmationOpen(true);
    } else {
      generateSecrets(childExternalSystem!.id!);
      setIsGenerateConfirmationOpen(false);
    }
  };

  const closeGenerateConfirmationPopup = () =>
    setIsGenerateConfirmationOpen(false);

  const submitGenerateIoTEdgeConnectionString = () => {
    generateSecrets(childExternalSystem!.id!);
    setIsGenerateConfirmationOpen(false);
  };

  return {
    deviceId,
    ioTEdgeConnectionString,
    generateIoTEdgeConnectionStringIsLoading: generateSecretIsLoading,
    isGenerateConfirmationOpen,
    defaultIoTEdgeConnectionString,

    generateIoTEdgeClusterDeviceConnectionString:
      generateIoTEdgeConnectionString,
    submitGenerateIoTEdgeClusterDeviceConnectionString:
      submitGenerateIoTEdgeConnectionString,
    closeGenerateConfirmationPopup,
  };
};

export default useExternalSystemSettingsIoTEdgeClusterClient;
