import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import Device from "features/device/domain/models/device";
import ReadDevicesCommand from "features/device/domain/models/read-devices-command";
import ReadDevicesResponse from "features/device/domain/models/read-devices-response";
import ValidateDeviceNameCommand from "features/device/domain/models/validate-device-name-command";
import ValidateDeviceExternalIdCommand from "features/device/domain/models/validate-device-functional-id-command";
import ReadFilterValuesResponse from "../models/read-filter-values-response";
import UpdateDeviceCommand from "../models/update-device-command";
import CreateDeviceCommand from "../models/create-device-command";

const baseUrl = "/api/v1/device";

export const deviceApi = createApi({
  reducerPath: "device",
  baseQuery,
  refetchOnMountOrArgChange: true,
  tagTypes: ["Devices", "DeviceFilters"],
  endpoints: (build) => ({
    createDevice: build.mutation<Device, CreateDeviceCommand>({
      query: (body) => ({
        url: `${baseUrl}/CreateDevice`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Devices", "DeviceFilters"],
    }),
    readDevices: build.query<ReadDevicesResponse, ReadDevicesCommand>({
      query: (command) => ({
        url: `${baseUrl}/ReadDevices`,
        method: "POST",
        body: command,
      }),
      providesTags: ["Devices"],
    }),
    readDevice: build.query<Device, string>({
      query: (deviceId) => ({
        url: `${baseUrl}/ReadDevice/${deviceId}`,
        method: "GET",
      }),
    }),
    updateDevice: build.mutation<Device, UpdateDeviceCommand>({
      query: (body) => ({
        url: `${baseUrl}/UpdateDevice`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Devices", "DeviceFilters"],
    }),
    deleteDevice: build.mutation<void, string>({
      query: (id) => ({
        url: `${baseUrl}/DeleteDevice/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Devices", "DeviceFilters"],
    }),
    readFilterValues: build.query<ReadFilterValuesResponse, void>({
      query: () => ({
        url: `${baseUrl}/ReadFilterValues`,
        method: "GET",
      }),
      providesTags: ["DeviceFilters"],
    }),
    validateDeviceName: build.mutation<void, ValidateDeviceNameCommand>({
      query: (body) => ({
        url: `${baseUrl}/ValidateDeviceName`,
        method: "POST",
        body,
      }),
    }),
    validateDeviceExternalId: build.mutation<
      void,
      ValidateDeviceExternalIdCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/ValidateDeviceExternalId`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useCreateDeviceMutation,
  useLazyReadDevicesQuery,
  useLazyReadDeviceQuery,
  useUpdateDeviceMutation,
  useDeleteDeviceMutation,
  useLazyReadFilterValuesQuery,
  useValidateDeviceNameMutation,
  useValidateDeviceExternalIdMutation,
} = deviceApi;
