import { ReactElement } from "react";
import OrganisationUnitScenarioIcon from "./organisation-unit-scenario-icon";
import { Grid } from "@mui/material";
import OrganisationUnitScenario from "../../domain/models/organisation-unit-scenario";
import "./resident-alarm-scenarios.scss";
import { ScenarioType } from "models/scenario-type";

interface IProps {
  organisationUnitScenarios: OrganisationUnitScenario[];
}

function ResidentAlarmScenarios(props: Readonly<IProps>): ReactElement {
  return (
    <Grid container className="resident-alarm-scenarios">
      <Grid item>
        <OrganisationUnitScenarioIcon
          sx={{ fontSize: 16 }}
          organisationUnitScenario={props.organisationUnitScenarios.find(
            (x) => x.scenarioType === ScenarioType.Bed,
          )}
          isVisible={props.organisationUnitScenarios.some(
            (x) => x.scenarioType === ScenarioType.Bed,
          )}
          scenarioType={ScenarioType.Bed}
        />
      </Grid>
      <Grid item>
        <OrganisationUnitScenarioIcon
          sx={{ fontSize: 16 }}
          organisationUnitScenario={props.organisationUnitScenarios.find(
            (x) => x.scenarioType === ScenarioType.Room,
          )}
          isVisible={props.organisationUnitScenarios.some(
            (x) => x.scenarioType === ScenarioType.Room,
          )}
          scenarioType={ScenarioType.Room}
        />
      </Grid>
      <Grid item>
        <OrganisationUnitScenarioIcon
          sx={{ fontSize: 16 }}
          organisationUnitScenario={props.organisationUnitScenarios.find(
            (x) => x.scenarioType === ScenarioType.Acoustic,
          )}
          isVisible={props.organisationUnitScenarios.some(
            (x) => x.scenarioType === ScenarioType.Acoustic,
          )}
          scenarioType={ScenarioType.Acoustic}
        />
      </Grid>
      <Grid item>
        <OrganisationUnitScenarioIcon
          sx={{ fontSize: 16 }}
          organisationUnitScenario={props.organisationUnitScenarios.find(
            (x) => x.scenarioType === ScenarioType.Video,
          )}
          isVisible={props.organisationUnitScenarios.some(
            (x) => x.scenarioType === ScenarioType.Video,
          )}
          scenarioType={ScenarioType.Video}
        />
      </Grid>
      <Grid item>
        <OrganisationUnitScenarioIcon
          sx={{ fontSize: 16 }}
          organisationUnitScenario={props.organisationUnitScenarios.find(
            (x) => x.scenarioType === ScenarioType.CurrentLocation,
          )}
          isVisible={props.organisationUnitScenarios.some(
            (x) => x.scenarioType === ScenarioType.CurrentLocation,
          )}
          scenarioType={ScenarioType.CurrentLocation}
        />
      </Grid>
    </Grid>
  );
}

export default ResidentAlarmScenarios;
