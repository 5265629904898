import {
  confirmationActionExecuted,
  showConfirmationPopup,
} from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { useDeviceDetailsContextProvider } from "features/device/device-details/providers/device-details-provider/device-details.provider";
import { useDevices } from "features/device/devices/hooks";
import { useDevicesContextProvider } from "features/device/devices/providers/devices-provider/devices.provider";
import Device from "features/device/domain/models/device";
import DeviceCommand from "features/device/domain/models/device-command";
import {
  useCreateDeviceMutation,
  useUpdateDeviceMutation,
} from "features/device/domain/reducers/device.reducer";
import { DeviceCommandType } from "features/device/models/device-command-type";
import { DeviceType } from "features/device/models/device-type";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useEffect, useState } from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";
import { ApiResponse, isErrorResponse } from "redux-base/create-api-utils";
import { ViewingMode } from "utils/viewing-utils";
import { v4 as uuidv4 } from "uuid";
import { DeviceMonitoringConstants } from "../views/device-details-settings/device-monitoring.component";
import CreateDeviceCommand from "features/device/domain/models/create-device-command";
import UpdateDeviceCommand from "features/device/domain/models/update-device-command";

export interface IDeviceDetailsHook {
  currentDevice: Device | undefined;
  handleClosePopup: () => void;
  handleCancelEdit: () => void;
  handleSetEditMode: () => void;
  handleDelete: () => void;
  submitDevice: (fieldValues: FieldValues) => void;
  generateExternalId: () => void;
  getDeviceCommand: (commandType: DeviceCommandType) => DeviceCommand;
  form: UseFormReturn<Partial<Device>, any>;
  allowUnlinking: boolean;
  isPopupOpen: boolean;
  isLoading: boolean;
  currentViewingMode: ViewingMode;
  currentDeviceType: DeviceType | undefined;
  currentExternalSystemId: string | undefined;
  getDefaultExpectedRecurrence: () => number;
}

export const useDeviceDetailsHook = (): IDeviceDetailsHook => {
  const dispatch = useDispatch();

  const [createDeviceMutation] = useCreateDeviceMutation();
  const [updateDeviceMutation] = useUpdateDeviceMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentDeviceType, setCurrentDeviceType] = useState<
    DeviceType | undefined
  >(undefined);
  const [currentExternalSystemId, setCurrentExternalSystemId] = useState<
    string | undefined
  >(undefined);

  const {
    deviceDetailsState: {
      currentDevice,
      currentViewingMode,
      setCurrentViewingMode,
      reloadCurrentDeviceById: reloadCurrentDevice,
      form,
      isLoadingDevice,
      isSavingDevice,
      setIsSavingDevice,
    },
  } = useDeviceDetailsContextProvider();

  const { confirmDeleteDevice } = useDevices();

  const watchType = form.watch("type");
  const watchExternalSystemId = form.watch("externalSystemId");

  const {
    devicesState: { closePopup, isPopupOpen, allowUnlinking },
  } = useDevicesContextProvider();

  useEffect(() => {
    setIsLoading(isLoadingDevice || isSavingDevice);
  }, [isLoadingDevice, isSavingDevice]);

  useEffect(() => {
    setCurrentDeviceType(form.getValues().type ?? currentDevice?.type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchType]);

  useEffect(() => {
    setCurrentExternalSystemId(
      form.getValues().externalSystemId ?? currentDevice?.externalSystemId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchExternalSystemId]);

  const handleResultFromApi = (response: ApiResponse<Device>) => {
    if (isErrorResponse(response)) {
      dispatch(
        setErrorMessage({
          error: response.error,
        }),
      );
    } else {
      reloadCurrentDevice(response.data);
      dispatch(confirmationActionExecuted());
      setCurrentViewingMode("viewing");
    }
  };

  const handleDelete = () => {
    if (currentDevice) {
      confirmDeleteDevice(currentDevice);
    }
  };

  const createDevice = async (
    fieldValues: FieldValues,
    commands: DeviceCommand[],
  ) => {
    const createCommand: CreateDeviceCommand = {
      externalId: fieldValues.externalId?.trim(),
      type: fieldValues.type,
      function: fieldValues.function,
      name: fieldValues.name?.trim(),
      note: fieldValues.note?.trim(),
      organisationUnitId: fieldValues.organisationUnitId,
      externalSystemId: fieldValues.externalSystemId,
      address: fieldValues.address?.trim(),
      source: fieldValues.source?.trim(),
      commands: commands,
      deviceMonitoring: fieldValues.deviceMonitoring,
    };
    setIsSavingDevice(true);
    let result = (await createDeviceMutation(
      createCommand,
    )) as ApiResponse<Device>;
    handleResultFromApi(result);
    setIsSavingDevice(false);
  };

  const updateDevice = async (
    fieldValues: FieldValues,
    commands: DeviceCommand[],
  ) => {
    const updateCommand: UpdateDeviceCommand = {
      id: currentDevice!.id!,
      externalId: fieldValues.externalId?.trim(),
      function: fieldValues.function,
      name: fieldValues.name?.trim(),
      note: fieldValues.note?.trim(),
      organisationUnitId: fieldValues.organisationUnitId,
      externalSystemId: fieldValues.externalSystemId,
      address: fieldValues.address?.trim(),
      source: fieldValues.source?.trim(),
      commands: commands,
      deviceMonitoring: fieldValues.deviceMonitoring,
    };
    setIsSavingDevice(true);
    let result = (await updateDeviceMutation(
      updateCommand,
    )) as ApiResponse<Device>;
    handleResultFromApi(result);
    setIsSavingDevice(false);
  };

  const submitDevice = (fieldValues: FieldValues) => {
    let index = 0;
    let filteredCommands: DeviceCommand[] = [];
    fieldValues.commands?.forEach((element: DeviceCommand) => {
      element.id = element.id === "" ? undefined : element.id;
      element.action = element.action?.trim();
      element.username =
        element.action?.trim() === "" ? undefined : element.username;
      element.password =
        element.action?.trim() === "" ? undefined : element.password;
      filteredCommands[index] = element;
      index++;
    });

    if (currentViewingMode === "creation") {
      createDevice(fieldValues, filteredCommands);
    } else {
      updateDevice(fieldValues, filteredCommands);
    }
  };

  const handleCancelEdit = () => {
    form.reset();
    setCurrentViewingMode("viewing");
  };

  const handleSetEditMode = () => {
    setCurrentViewingMode("editing");
  };

  const handleClosePopup = () => {
    if (
      (currentViewingMode === "creation" || currentViewingMode === "editing") &&
      form.formState.isDirty
    ) {
      dispatch(
        showConfirmationPopup({
          showInstantly: true,
          confirmActionNextAction: () => {
            setCurrentViewingMode("viewing");
            closePopup();
          },
        }),
      );
    } else {
      setCurrentViewingMode("viewing");
      closePopup();
    }
  };

  const generateExternalId = () => {
    form.setValue("externalId", uuidv4(), { shouldDirty: true });
  };

  const getDeviceCommand = (commandType: DeviceCommandType) => {
    let currentDeviceCommand = currentDevice?.commands?.find(
      (entry) => entry.type === commandType,
    );
    return (
      currentDeviceCommand ?? {
        type: commandType,
      }
    );
  };

  function getDefaultExpectedRecurrence(): number {
    if (currentDevice?.deviceMonitoring?.expectedRecurrence) {
      return currentDevice.deviceMonitoring.expectedRecurrence;
    }

    const deviceType = form.getValues("type");
    switch (deviceType) {
      case DeviceType.Universal:
      case DeviceType.UniversalAudio:
      case DeviceType.UniversalCamera:
      case DeviceType.UniversalIntercom:
        return DeviceMonitoringConstants.OneHourValue;
      case DeviceType.UniversalAlarmTransmitter:
        return DeviceMonitoringConstants.TwentyFourHoursValue;
      default:
        return DeviceMonitoringConstants.OneHourValue;
    }
  }

  return {
    currentDevice,
    submitDevice,
    handleClosePopup,
    handleCancelEdit,
    handleSetEditMode,
    handleDelete,
    generateExternalId,
    currentDeviceType,
    currentExternalSystemId,
    getDeviceCommand,
    form,
    isLoading,
    allowUnlinking,
    isPopupOpen,
    currentViewingMode,
    getDefaultExpectedRecurrence,
  };
};
