import "./App.scss";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./AppInsights";
import Routing from "./features/routing/routing";
import ErrorMessage from "features/error-handling/views/error-message.component";
import ConfirmationPopup from "features/confirmation-popup/views/confirmation-popup.component";
import { AuthenticationProvider } from "features/authentication/providers/authentication.provider";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/nl";
import TenantAccessIndicator from "features/tenants/components/tenant-access-indicator.component";
import { LoggingProvider } from "features/configuration/domain/providers/logging.provider";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="nl">
      <AppInsightsContext.Provider value={reactPlugin}>
        <AuthenticationProvider>
          <LoggingProvider>
            <div className="App">
              <TenantAccessIndicator />
              <Routing />
              <ErrorMessage />
              <ConfirmationPopup />
            </div>
          </LoggingProvider>
        </AuthenticationProvider>
      </AppInsightsContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
