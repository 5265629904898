import OutOfBedIcon from "components/custom-icons/out-of-bed-icon";
import OutOfRoomIcon from "components/custom-icons/out-of-room-icon";
import { ReactElement } from "react";
import { ScenarioType } from "models/scenario-type";
import { SxProps, Theme } from "@mui/material";
import OrganisationUnitScenario from "../../domain/models/organisation-unit-scenario";
import {
  Hearing,
  PinDropOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import Constants from "style/constants";

interface IProps {
  organisationUnitScenario?: OrganisationUnitScenario;
  scenarioType?: ScenarioType;
  sx?: SxProps<Theme>;
  isVisible?: boolean;
}

function OrganisationUnitScenarioIcon(props: Readonly<IProps>): ReactElement {
  const checkScenarioType = (scenarioType: ScenarioType): boolean =>
    (props.organisationUnitScenario !== undefined &&
      props.organisationUnitScenario.scenarioType === scenarioType) ||
    (props.scenarioType !== undefined && props.scenarioType === scenarioType);

  const getColor = (): string => {
    if (props.isVisible === undefined || !!props.isVisible) {
      return props.organisationUnitScenario !== undefined &&
        !props.organisationUnitScenario.isActive
        ? Constants.Colors.inactive
        : Constants.Colors.primary;
    }

    return "transparent";
  };

  return (
    <>
      {checkScenarioType(ScenarioType.CurrentLocation) && (
        <PinDropOutlined
          sx={{
            ...props.sx,
            color: getColor(),
          }}
        />
      )}
      {checkScenarioType(ScenarioType.Bed) && (
        <OutOfBedIcon sx={props.sx} color={getColor()} />
      )}
      {checkScenarioType(ScenarioType.Room) && (
        <OutOfRoomIcon sx={props.sx} color={getColor()} />
      )}
      {checkScenarioType(ScenarioType.Video) && (
        <VideocamOutlined
          sx={{
            ...props.sx,
            color: getColor(),
          }}
        />
      )}
      {checkScenarioType(ScenarioType.Acoustic) && (
        <Hearing
          sx={{
            ...props.sx,
            color: getColor(),
          }}
        />
      )}
    </>
  );
}

export default OrganisationUnitScenarioIcon;
