import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDeviceDetailsHook } from "../../hooks";
import { MenuItem, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import SelectField from "components/formFields/select-field.component";

export const DeviceMonitoringConstants = {
  OneHourValue: 60,
  TwelveHoursValue: 720,
  TwentyFourHoursValue: 1440,
};

function DeviceMonitoring(): ReactElement {
  const { t } = useTranslation("deviceDetails");

  const { currentViewingMode, currentDevice, getDefaultExpectedRecurrence } =
    useDeviceDetailsHook();
  const { control } = useFormContext();

  const deviceMonitoringOptions = [
    <MenuItem
      data-testid={`device-monitoring-one-hour`}
      key={`device-monitoring-one-hour`}
      value={DeviceMonitoringConstants.OneHourValue}
    >
      {`${DeviceMonitoringConstants.OneHourValue / 60} ${t(
        "deviceMonitoringOptions.hours",
      )}`}
    </MenuItem>,
    <MenuItem
      data-testid={`device-monitoring-twelve-hours`}
      key={`device-monitoring-twelve-hours`}
      value={DeviceMonitoringConstants.TwelveHoursValue}
    >
      {`${DeviceMonitoringConstants.TwelveHoursValue / 60} ${t(
        "deviceMonitoringOptions.hours",
      )}`}
    </MenuItem>,
    <MenuItem
      data-testid={`device-monitoring-twenty-four-hours`}
      key={`device-monitoring-twenty-four-hours`}
      value={DeviceMonitoringConstants.TwentyFourHoursValue}
    >
      {`${DeviceMonitoringConstants.TwentyFourHoursValue / 60} ${t(
        "deviceMonitoringOptions.hours",
      )}`}
    </MenuItem>,
  ];

  return (
    <>
      {currentDevice?.deviceMonitoring?.expectedRecurrence &&
        currentViewingMode === "viewing" && (
          <Typography variant="subtitle1">
            {t("deviceMonitoringOptions.description", {
              hours: currentDevice.deviceMonitoring.expectedRecurrence / 60,
            })}
          </Typography>
        )}

      {(currentViewingMode === "creation" ||
        currentViewingMode === "editing") && (
        <ViewingModeController
          viewingMode={currentViewingMode}
          name={`deviceMonitoring.expectedRecurrence`}
          control={control}
          label={t("deviceMonitoringOptions.label")}
          render={({ field }) => (
            <SelectField
              {...field}
              inputProps={{ "data-testid": "MonitoringExpectedRecurrence" }}
              id="deviceMonitoring.expectedRecurrence"
              variant="outlined"
              label={t("deviceMonitoringOptions.label")}
              className="select-field"
              value={`${field.value ?? getDefaultExpectedRecurrence()}`}
            >
              {deviceMonitoringOptions}
            </SelectField>
          )}
        />
      )}
    </>
  );
}

export default DeviceMonitoring;
