import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { ColumnDefinition } from "components/table/columnDefinition";
import { ReactElement } from "react";
import { NestedKeyof } from "utils/nested-keyof-utils";

export type OrderDirection = "asc" | "desc";

interface IProps<DataType extends object, Key extends NestedKeyof<DataType>> {
  columns: ColumnDefinition<DataType, Key>[];
  orderDirection: OrderDirection;
  orderBy: string;
  onRequestSort: (property: Key) => void;
  hasOverflowMenu: boolean;
  rowsAreSelectable?: boolean;
}

export function TableHeader<
  DataType extends object,
  Key extends NestedKeyof<DataType>,
>(props: Readonly<IProps<DataType, Key>>): ReactElement {
  return (
    <TableHead>
      <TableRow>
        {props.rowsAreSelectable && <TableCell padding="checkbox" />}
        {props.columns.map((column, index) => (
          <TableCell
            className="table-cell"
            data-testid={`table-header-${index}`}
            key={`table-header-${column.key}`}
          >
            {column.disableSort ? (
              column.label
            ) : (
              <TableSortLabel
                data-testid={`${column.key.toString()}-sorter`}
                active={props.orderBy === column.key}
                direction={
                  props.orderBy === column.key ? props.orderDirection : "asc"
                }
                onClick={() => props.onRequestSort(column.key)}
              >
                {column.label}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {props.hasOverflowMenu && <TableCell />}
      </TableRow>
    </TableHead>
  );
}
