import "./external-system-details-popup-header.scss";
import { Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CheckRounded from "@mui/icons-material/CheckRounded";
import { useTranslation } from "react-i18next";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";
import Permission from "features/autorisation/domain/models/permission";
import { LoadingButton } from "@mui/lab";
import { ReactElement } from "react";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import { useFormContext } from "react-hook-form";

function ExternalSystemDetailsPopupHeader(): ReactElement {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const { formState } = useFormContext();

  return (
    <div className="external-system-details-header">
      {(externalSystemDetailsHook.viewingMode === "creation" ||
        externalSystemDetailsHook.viewingMode === "editing") && (
        <>
          <Button
            data-testid="externalSystemDetailCancelButton"
            variant="text"
            onClick={externalSystemDetailsHook.cancelMutation}
            disabled={externalSystemDetailsHook.mutationIsLoading}
          >
            {t("details.cancelFormButton")}
          </Button>
          <LoadingButton
            data-testid="externalSystemDetailSubmitButton"
            startIcon={<CheckRounded />}
            variant="contained"
            type="submit"
            loading={externalSystemDetailsHook.mutationIsLoading}
            loadingPosition="start"
          >
            {t("details.saveFormButton")}
          </LoadingButton>
        </>
      )}
      {externalSystemDetailsHook.viewingMode === "viewing" && (
        <>
          <AutorisationWrapper
            atLeastOnePermissionOf={[Permission.DeleteExternalSystem]}
          >
            <Button
              data-testid="externalSystemDetailRemoveButton"
              startIcon={<DeleteForeverOutlinedIcon className="icon-on-text" />}
              variant="text"
              onClick={() =>
                externalSystemDetailsHook.deleteExternalSystem(
                  externalSystemDetailsHook.currentSelectedExternalSystem!,
                )
              }
              disabled={externalSystemDetailsHook.mutationIsLoading}
            >
              {t("details.removeFormButton")}
            </Button>
          </AutorisationWrapper>
          <AutorisationWrapper
            atLeastOnePermissionOf={[Permission.UpdateExternalSystem]}
          >
            <Button
              data-testid="externalSystemDetailEditButton"
              startIcon={
                <ModeEditOutlineOutlinedIcon className="icon-on-text" />
              }
              variant="text"
              onClick={() =>
                externalSystemDetailsHook.openDetailsInEditingMode(
                  externalSystemDetailsHook.currentSelectedExternalSystem!,
                )
              }
              disabled={externalSystemDetailsHook.mutationIsLoading}
            >
              {t("details.editFormButton")}
            </Button>
          </AutorisationWrapper>
        </>
      )}

      <IconButton
        aria-label="close"
        className="close-button"
        onClick={() =>
          externalSystemDetailsHook.closeDetails(formState.isDirty)
        }
        data-testid="externalSystemDetailsPopupCloseButton"
      >
        <Close />
      </IconButton>
    </div>
  );
}

export default ExternalSystemDetailsPopupHeader;
