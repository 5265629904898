enum PermissionGroup {
  App = "App",
  AppMonitoring = "AppMonitoring",
  Dashboard = "Dashboard",
  Devices = "Devices",
  History = "History",
  User = "User",
  OrganisationUnit = "OrganisationUnit",
  ResidentsSettings = "ResidentsSettings",
  Scenario = "Scenario",
  TemporaryAccess = "TemporaryAccess",
  Settings = "Settings",
  CentralSupportPortal = "CentralSupportPortal",
}

export default PermissionGroup;
