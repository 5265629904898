import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import OrganisationUnit from "features/organisation/domain/models/organisation-unit";
import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import EditOrganisationUnitCommand from "features/organisation/domain/models/edit-organisation-unit-command";
import CreateOrganisationUnitCommand from "features/organisation/domain/models/create-organisation-unit-command";
import GetOrganisationUnitTreeQuery from "../models/get-organisation-unit-tree-query";
import qs from "qs";

const baseUrl = "/api/v1/OrganisationUnit";

export const organisationUnitApi = createApi({
  reducerPath: "organisationUnit",
  baseQuery,
  tagTypes: [
    "OrganisationUnitTree",
    "OrganisationUnit",
    "MyOrganisationUnitTree",
    "OrganisationUnitTreeForExternalSystem",
    "ReadOrganisationUnitsForTemporaryAccess",
    "ReadOrganisationUnitsForSettings",
    "ReadTopLevelOrganisationUnits",
    "OrganisationUnitTreeWithFaults",
  ],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    createOrganisationUnit: build.mutation<
      OrganisationUnit,
      CreateOrganisationUnitCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/NewOrganisationUnit`,
        method: "POST",
        body,
      }),
      invalidatesTags: [
        "OrganisationUnitTree",
        "MyOrganisationUnitTree",
        "OrganisationUnitTreeForExternalSystem",
        "OrganisationUnitTreeWithFaults",
      ],
    }),
    updateOrganisationUnit: build.mutation<
      OrganisationUnit,
      EditOrganisationUnitCommand
    >({
      query: (body) => ({
        url: `${baseUrl}/EditOrganisationUnit`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (_, error, command) =>
        error
          ? []
          : [
              "OrganisationUnitTree",
              "MyOrganisationUnitTree",
              "OrganisationUnitTreeForExternalSystem",
              "OrganisationUnitTreeWithFaults",
              { type: "OrganisationUnit", id: command.id },
            ],
    }),
    deleteOrganisationUnit: build.mutation<void, string>({
      query: (id) => ({
        url: `${baseUrl}/DeleteOrganisationUnit/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (_, error, id) =>
        error
          ? []
          : [
              "OrganisationUnitTree",
              "MyOrganisationUnitTree",
              "OrganisationUnitTreeForExternalSystem",
              "OrganisationUnitTreeWithFaults",
              { type: "OrganisationUnit", id },
            ],
    }),
    getOrganisationUnitTree: build.query<
      Array<OrganisationTreeNode>,
      GetOrganisationUnitTreeQuery
    >({
      query: (getOrganisationUnitTreeQuery) => ({
        url: `${baseUrl}/GetOrganisationUnitTree?${qs.stringify(
          getOrganisationUnitTreeQuery,
          {
            allowDots: true,
          },
        )}`,
        method: "GET",
      }),
      providesTags: ["OrganisationUnitTree"],
    }),
    getMyOrganisationUnitTree: build.query<
      Array<OrganisationTreeNode>,
      GetOrganisationUnitTreeQuery
    >({
      query: (getOrganisationUnitTreeQuery) => ({
        url: `${baseUrl}/GetMyOrganisationUnitTree?${qs.stringify(
          getOrganisationUnitTreeQuery,
          {
            allowDots: true,
          },
        )}`,
        method: "GET",
      }),
      providesTags: ["MyOrganisationUnitTree"],
    }),
    getOrganisationUnitTreeForExternalSystem: build.query<
      Array<OrganisationTreeNode>,
      string
    >({
      query: (externalSystemId) => ({
        url: `${baseUrl}/GetOrganisationUnitTreeForExternalSystem/${externalSystemId}`,
        method: "GET",
      }),
      providesTags: (_result, _error, externalSystemId) => [
        { type: "OrganisationUnitTreeForExternalSystem", id: externalSystemId },
      ],
    }),
    getOrganisationUnitTreeWithFaults: build.query<
      Array<OrganisationTreeNode>,
      void
    >({
      query: () => ({
        url: `${baseUrl}/GetOrganisationUnitTreeWithFaults`,
        method: "GET",
      }),
      providesTags: ["OrganisationUnitTreeWithFaults"],
    }),
    ReadOrganisationUnitsForTemporaryAccess: build.query<
      Array<OrganisationUnit>,
      void
    >({
      query: () => ({
        url: `${baseUrl}/ReadOrganisationUnitsForTemporaryAccess`,
        method: "GET",
      }),
      providesTags: ["ReadOrganisationUnitsForTemporaryAccess"],
    }),
    ReadOrganisationUnitsForSettings: build.query<
      Array<OrganisationUnit>,
      void
    >({
      query: () => ({
        url: `${baseUrl}/ReadOrganisationUnitsForSettings`,
        method: "GET",
      }),
      providesTags: ["ReadOrganisationUnitsForSettings"],
    }),
    getOrganisationUnit: build.query<OrganisationUnit, string>({
      query: (id) => ({
        url: `${baseUrl}/GetOrganisationUnit/${id}`,
        method: "GET",
      }),
      providesTags: (result) =>
        result ? [{ type: "OrganisationUnit", id: result.id }] : [],
    }),
  }),
});

export const {
  useCreateOrganisationUnitMutation,
  useUpdateOrganisationUnitMutation,
  useDeleteOrganisationUnitMutation,
  useGetOrganisationUnitTreeQuery,
  useGetMyOrganisationUnitTreeQuery,
  useLazyGetOrganisationUnitTreeForExternalSystemQuery,
  useLazyGetOrganisationUnitQuery,
  useLazyReadOrganisationUnitsForTemporaryAccessQuery,
  useReadOrganisationUnitsForSettingsQuery,
  useGetOrganisationUnitQuery,
  useGetOrganisationUnitTreeWithFaultsQuery,
} = organisationUnitApi;
