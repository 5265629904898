import { useState } from "react";

export interface IConfirmationPopupHook {
  isPopupVisible: boolean;
  setUnsavedChanges: (unsavedChanges: boolean) => void;
  showConfirmationPopup: (action: () => void) => void;
  confirm: (confirm: boolean) => void;
}

const useConfirmationPopupHook = (): IConfirmationPopupHook => {
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const [confirmationAction, setConfirmationAction] =
    useState<() => () => void>();

  const changeUnsavedChanges = (shouldShow: boolean) =>
    setUnsavedChanges(shouldShow);

  const showConfirmationPopup = (action: () => void) => {
    if (unsavedChanges) {
      setConfirmationAction(() => action);
      setIsPopupVisible(true);
      return;
    }

    action();
  };

  const confirm = (confirm: boolean) => {
    setIsPopupVisible(false);

    if (confirm && confirmationAction) {
      confirmationAction();
      setConfirmationAction(undefined);
    }
  };

  return {
    isPopupVisible,
    setUnsavedChanges: changeUnsavedChanges,
    showConfirmationPopup,
    confirm,
  };
};

export default useConfirmationPopupHook;
