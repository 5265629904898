import { useExternalSystemsContextProvider } from "../context/external-systems-provider";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useGenerateSecretsMutation } from "../domain/reducers/external-system.reducer";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";

export interface IExternalSystemSettingsViewpointClientHook {
  clientId: string | undefined;
  clientSecret: string;
  generateSecretIsLoading: boolean;
  isGenerateSecretConfirmationOpen: boolean;
  defaultSecret: string;

  generateSecret: () => void;
  submitGenerateSecret: () => void;
  closeGenerateSecretConfirmationPopup: () => void;
}

const useExternalSystemSettingsViewpointClient =
  (): IExternalSystemSettingsViewpointClientHook => {
    const dispatch = useDispatch();

    const { externalSystemDetailsHook: detailView } =
      useExternalSystemsContextProvider();

    const defaultSecret = "********";

    const [
      isGenerateSecretConfirmationOpen,
      setIsGenerateSecretConfirmationOpen,
    ] = useState(false);
    const [clientId, setClientId] = useState(
      detailView.currentSelectedExternalSystem?.clientId,
    );
    const [clientSecret, setClientSecret] = useState(defaultSecret);

    const [
      generateSecrets,
      {
        isLoading: generateSecretIsLoading,
        isError: generateSecretIsError,
        error: generateSecretError,
        isSuccess: generateSecretIsSuccess,
        data: generateSecretData,
      },
    ] = useGenerateSecretsMutation();

    useEffect(() => {
      if (generateSecretIsError && generateSecretError) {
        dispatch(
          setErrorMessage({
            error: generateSecretError,
          }),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateSecretIsError]);

    useEffect(() => {
      if (generateSecretData) {
        setClientId(generateSecretData.clientId);
        setClientSecret(generateSecretData.secret);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generateSecretIsSuccess]);

    useEffect(() => {
      setClientId(detailView.currentSelectedExternalSystem?.clientId);
    }, [detailView.currentSelectedExternalSystem]);

    const generateSecret = () => {
      if (clientId && clientId.length > 0) {
        setIsGenerateSecretConfirmationOpen(true);
      } else {
        generateSecrets(detailView.currentSelectedExternalSystem!.id!);
        setIsGenerateSecretConfirmationOpen(false);
      }
    };

    const closeGenerateSecretConfirmationPopup = () =>
      setIsGenerateSecretConfirmationOpen(false);

    const submitGenerateSecret = () => {
      generateSecrets(detailView.currentSelectedExternalSystem!.id!);
      setIsGenerateSecretConfirmationOpen(false);
    };

    return {
      clientId,
      clientSecret,
      generateSecretIsLoading,
      isGenerateSecretConfirmationOpen,
      defaultSecret,

      generateSecret,
      submitGenerateSecret,
      closeGenerateSecretConfirmationPopup,
    };
  };

export default useExternalSystemSettingsViewpointClient;
