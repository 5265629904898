import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "./external-system-settings-offline-portal-credentials.scss";
import { ReactElement, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import useExternalSystemValidation from "features/external-system/hooks/external-system-validation-hook";

function ExternalSystemSettingsOfflinePortalCredentials(): ReactElement {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { t } = useTranslation("externalSystem");
  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();
  const { validatePassword } = useExternalSystemValidation();
  const [showPassword, setShowPassword] = useState(false);

  const registerOptions = {
    offlinePortalUsername: {
      required: t("details.information.requiredHelperText"),
    },
    offlinePortalPassword: {
      required: t("details.information.requiredHelperText"),
      validate: validatePassword,
    },
  };

  function getDottedPassword(): string {
    return "*".repeat(10);
  }

  return (
    <>
      {externalSystemDetailsHook.viewingMode === "viewing" && (
        <div className="external-system-offline-portal-credentials viewing">
          <Typography variant="subtitle1" data-testid="usernameLabel">
            {t("details.settings.offlinePortalCredentials.username")}:{" "}
            {!externalSystemDetailsHook.currentSelectedExternalSystem
              ?.offlinePortalUsername ||
            externalSystemDetailsHook.currentSelectedExternalSystem
              ?.offlinePortalUsername === ""
              ? t(`details.settings.offlinePortalCredentials.noUsernameDefined`)
              : externalSystemDetailsHook.currentSelectedExternalSystem
                  ?.offlinePortalUsername}
          </Typography>
          <Typography variant="subtitle1" data-testid="passwordLabel">
            {t("details.settings.offlinePortalCredentials.password")}:{" "}
            {!externalSystemDetailsHook.currentSelectedExternalSystem
              ?.offlinePortalPassword ||
            externalSystemDetailsHook.currentSelectedExternalSystem
              ?.offlinePortalPassword === ""
              ? t(`details.settings.offlinePortalCredentials.noPasswordDefined`)
              : getDottedPassword()}
          </Typography>
        </div>
      )}
      {externalSystemDetailsHook.viewingMode !== "viewing" && (
        <div className="external-system-offline-portal-credentials">
          <Controller
            name={"offlinePortalUsername"}
            control={control}
            rules={registerOptions.offlinePortalUsername}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  "data-testid": `offlinePortalUsername`,
                }}
                id="offlinePortalUsername"
                label={t("details.settings.offlinePortalCredentials.username")}
                variant="outlined"
                className="form-field-credentials"
                error={!!errors.offlinePortalUsername}
                helperText={errors.offlinePortalUsername?.message?.toString()}
              />
            )}
          />
          <Controller
            name={"offlinePortalPassword"}
            control={control}
            rules={registerOptions.offlinePortalPassword}
            render={({ field }) => (
              <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                inputProps={{
                  "data-testid": `offlinePortalPassword`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="offlinePortalPassword"
                label={t("details.settings.offlinePortalCredentials.password")}
                variant="outlined"
                className="form-field-credentials"
                error={!!errors.offlinePortalPassword}
                helperText={errors.offlinePortalPassword?.message?.toString()}
              />
            )}
          />
        </div>
      )}
    </>
  );
}

export default ExternalSystemSettingsOfflinePortalCredentials;
