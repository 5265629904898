import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem } from "components/table/table";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NestedKeyof } from "utils/nested-keyof-utils";
import { useAppDispatch } from "redux-base/store";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import Scenario from "features/scenario/models/scenario";
import { useReadScenariosQuery } from "features/scenario/domain/reducers/scenarios.reducer";
import ScenarioName from "features/scenario/views/scenario-name.component";
import "./linkable-scenarios-table.component.scss";
import Permission from "features/autorisation/domain/models/permission";
import AutorisationWrapper from "features/autorisation/views/autorisation-wrapper";

interface IProps {
  onSelectedScenariosChanged: (scenarios: Scenario[]) => void;
  scenariosToHide: Scenario[];
}

function LinkableScenariosTable(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("linkableScenarios");
  const dispatch = useAppDispatch();

  const columns: ColumnDefinition<Scenario, NestedKeyof<Scenario>>[] = [
    {
      key: "scenarioType",
      label: t("linkableScenariosTable.column.name"),
      disableSort: true,
      renderCustomContentProvider: (scenario) => (
        <ScenarioName scenarioType={scenario.scenarioType} />
      ),
    },
  ];

  const {
    data: readScenariosData,
    isSuccess: readScenariosIsSuccess,
    isLoading: readScenariosIsLoading,
    error: readScenariosError,
  } = useReadScenariosQuery();

  const hiddenAndLiveDataMergedList =
    readScenariosData?.scenarios?.filter(
      (scenario) =>
        props.scenariosToHide.findIndex(
          (scenarioToHide) => scenarioToHide.id === scenario.id,
        ) === -1,
    ) ?? [];

  useEffect(() => {
    if (readScenariosError) {
      dispatch(
        setErrorMessage({
          error: readScenariosError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readScenariosError]);

  return (
    <AutorisationWrapper atLeastOnePermissionOf={[Permission.LinkScenario]}>
      <>
        {readScenariosIsLoading && <LoadingIndicator />}
        {readScenariosIsSuccess && readScenariosData && (
          <Table
            className="linkable-scenarios-table"
            data={hiddenAndLiveDataMergedList.map<IRowItem<Scenario>>(
              (scenario) => ({ data: scenario }),
            )}
            columns={columns}
            rowsAreSelectable={true}
            onSelectedRowsChanged={(scenarios: Scenario[]) =>
              props.onSelectedScenariosChanged(scenarios)
            }
            rowIdentifier={(item: Scenario) => item.id}
          />
        )}
      </>
    </AutorisationWrapper>
  );
}

export default LinkableScenariosTable;
