import { MenuItem, Select, Typography } from "@mui/material";
import { ExternalSystemType } from "features/external-system/domain/models/external-system-type";
import { Controller, useFormContext } from "react-hook-form";
import "./external-system-settings-type.scss";
import { useTranslation } from "react-i18next";
import { ReactElement } from "react";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";

function ExternalSystemSettingsType(): ReactElement {
  const { control } = useFormContext();
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  if (externalSystemDetailsHook.viewingMode === "creation") {
    return (
      <Controller
        name="type"
        control={control}
        render={({ field }) => {
          return (
            <Select
              {...field}
              inputProps={{ "data-testid": "typeInput" }}
              id="typeInput"
              variant="outlined"
              className="input-field"
            >
              {Object.values(ExternalSystemType).map((systemType) => (
                <MenuItem
                  data-testid={`external-system-type-${systemType}`}
                  key={`externalSystemType-${systemType}`}
                  value={systemType}
                >
                  {t(`details.settings.type.${systemType}`)}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />
    );
  } else {
    return (
      <Typography variant="subtitle1" data-testid="externalSystemSettingsType">
        {t(
          `details.settings.type.${externalSystemDetailsHook.currentSelectedExternalSystem?.type}`,
        )}
      </Typography>
    );
  }
}

export default ExternalSystemSettingsType;
