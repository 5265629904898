import PopUp from "components/pop-up/pop-up.component";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useConfirmationPopupContextProvider } from "../context/confirmation-popup-provider";

function ProvidedConfirmationPopup(): ReactElement {
  const { t } = useTranslation("confirmationPopup");
  const { isPopupVisible, confirm } = useConfirmationPopupContextProvider();

  return (
    <PopUp
      isOpen={isPopupVisible}
      title={t("title")}
      body={t("body")}
      primaryButtonText={t("primaryButtonText")}
      secondaryButtonText={t("secondaryButtonText")}
      handleOnClose={() => confirm(false)}
      secondaryButtonAction={() => confirm(false)}
      primaryButtonAction={() => confirm(true)}
    />
  );
}

export default ProvidedConfirmationPopup;
