import { useLazyReadSystemsQuery } from "../domain/reducers/external-system.reducer";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { ReadExternalSystemsResponse } from "../domain/models/read-external-systems-response";
import useExternalSystemDetails, {
  IExternalSystemDetailsHook,
} from "../hooks/external-system-details-hook";
import useExternalSystemFilters, {
  IExternalSystemFiltersHook,
} from "../hooks/external-system-filters-hook";
import ExternalSystem from "../domain/models/external-system";

export interface IExternalSystemsHook {
  externalSystemsResponse: ReadExternalSystemsResponse | undefined;
  externalSystemsResponseIsLoading: boolean;
  externalSystemsResponseIsSuccess: boolean;

  externalSystemDetailsHook: IExternalSystemDetailsHook;
  externalSystemFilterHook: IExternalSystemFiltersHook;

  refresh: () => void;

  openDetailsInCreationModeAndRefresh: () => void;
  openDetailsInViewingModeAndRefresh: (externalSystem: ExternalSystem) => void;
  openDetailsInEditingModeAndRefresh: (externalSystem: ExternalSystem) => void;
}

const useExternalSystems = (): IExternalSystemsHook => {
  const dispatch = useDispatch();

  const externalSystemDetailsHook = useExternalSystemDetails();

  const externalSystemFilterHook = useExternalSystemFilters();

  const [
    triggerReadExternalSystems,
    {
      data: externalSystemsResponse,
      error: externalSystemsResponseError,
      isError: externalSystemsResponseIsError,
      isLoading: externalSystemsResponseIsLoading,
      isSuccess: externalSystemsResponseIsSuccess,
    },
  ] = useLazyReadSystemsQuery();

  useEffect(() => {
    triggerReadExternalSystems(
      externalSystemFilterHook.readExternalSystemsQuery,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalSystemFilterHook.readExternalSystemsQuery]);

  useEffect(() => {
    if (externalSystemDetailsHook.currentSelectedExternalSystem) {
      const updatedExternalSystem =
        externalSystemsResponse?.externalSystems.find(
          (system) =>
            system.id ===
            externalSystemDetailsHook.currentSelectedExternalSystem!.id,
        );
      externalSystemDetailsHook.changeCurrentSelectedExternalSystem(
        updatedExternalSystem,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalSystemsResponse]);

  useEffect(() => {
    if (externalSystemsResponseIsError && externalSystemsResponseError) {
      dispatch(
        setErrorMessage({
          error: externalSystemsResponseError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalSystemsResponseIsError]);

  const refresh = () => {
    triggerReadExternalSystems(
      externalSystemFilterHook.readExternalSystemsQuery,
    );
    externalSystemFilterHook.loadMonitorFilters();
  };

  const openDetailsInCreationModeAndRefresh = () => {
    refresh();
    externalSystemDetailsHook.openDetailsInCreationMode();
  };

  const openDetailsInEditingModeAndRefresh = (
    externalSystem: ExternalSystem,
  ) => {
    refresh();
    externalSystemDetailsHook.openDetailsInEditingMode(externalSystem);
  };

  const openDetailsInViewingModeAndRefresh = (
    externalSystem: ExternalSystem,
  ) => {
    refresh();
    externalSystemDetailsHook.openDetailsInViewingMode(externalSystem);
  };

  return {
    externalSystemsResponse,
    externalSystemsResponseIsLoading,
    externalSystemsResponseIsSuccess,

    externalSystemDetailsHook,
    externalSystemFilterHook,

    refresh,

    openDetailsInCreationModeAndRefresh,
    openDetailsInViewingModeAndRefresh,
    openDetailsInEditingModeAndRefresh,
  };
};

export default useExternalSystems;
