import Device from "features/device/domain/models/device";
import SendTestEventCommand, {
  EventPayload,
} from "../domain/models/send-test-event-command";
import { EventType } from "../domain/models/event-type";
import { useEffect, useState } from "react";
import { UseFormReturn, useForm } from "react-hook-form";
import { useSendTestEventMutation } from "../domain/reducers/send-test-event.reducer";
import { useDispatch } from "react-redux";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useTranslation } from "react-i18next";

export interface ISendTestEventHook {
  isSendTestEventPopupOpen: boolean;
  selectedDevice: Device | undefined;
  openSendTestEventPopup: (device: Device) => void;
  closePopup: () => void;
  form: UseFormReturn<Partial<SendTestEventCommand>, any>;
  submitTestEvent: (eventType: EventType, eventPayload: EventPayload) => void;
  sendTestEventIsLoading: boolean;
}

const useSendTestEvent = (): ISendTestEventHook => {
  const dispatch = useDispatch();
  const { t } = useTranslation("sendTestEvent");
  const [isSendTestEventPopupOpen, setIsSendTestEventPopupOpen] =
    useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = useState<Device>();
  const [
    sendEvent,
    {
      isLoading: sendTestEventIsLoading,
      isSuccess: sendTestEventIsSuccess,
      isError: sendTestEventIsError,
      error: sendTestEventError,
    },
  ] = useSendTestEventMutation();

  const form = useForm({
    mode: "onBlur",
    defaultValues: {},
  });

  useEffect(() => {
    form.reset({
      externalSystemId: selectedDevice?.externalSystemId,
      deviceId: selectedDevice?.id,
      eventType: EventType.Assistance,
      eventPayload: { message: t("defaultMessage"), location: "" },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  useEffect(() => {
    if (sendTestEventIsSuccess) {
      closePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendTestEventIsSuccess]);

  useEffect(() => {
    if (sendTestEventIsError && sendTestEventError) {
      dispatch(
        setErrorMessage({
          error: sendTestEventError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendTestEventIsError]);

  const submitTestEvent = (
    eventType: EventType,
    eventPayload: EventPayload,
  ) => {
    if (selectedDevice == null) {
      return;
    }
    const command: SendTestEventCommand = {
      eventType,
      eventPayload,
      deviceId: selectedDevice.id!,
    };
    sendEvent(command);
  };

  const openSendTestEventPopup = (device: Device) => {
    setSelectedDevice(device);
    setIsSendTestEventPopupOpen(true);
  };

  const closePopup = () => {
    setIsSendTestEventPopupOpen(false);
    setSelectedDevice(undefined);
  };
  return {
    isSendTestEventPopupOpen,
    selectedDevice,
    openSendTestEventPopup,
    closePopup,
    form,
    submitTestEvent,
    sendTestEventIsLoading,
  };
};

export default useSendTestEvent;
