import Device from "features/device/domain/models/device";
import { ViewingMode } from "utils/viewing-utils";
import { useEffect, useState } from "react";
import { useLazyReadDeviceQuery } from "features/device/domain/reducers/device.reducer";
import { UseFormReturn, useForm } from "react-hook-form";
import { setShouldShowConfirmation } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { useDispatch } from "react-redux";

export interface IDeviceDetailsState {
  currentDevice?: Device;
  currentViewingMode: ViewingMode;
  isLoadingDevice: boolean;
  isSavingDevice: boolean;
  form: UseFormReturn<Partial<Device>, any>;
  resetForm: (device?: Device) => void;
  setIsLoadingDevice: (isLoading: boolean) => void;
  setIsSavingDevice: (isSaving: boolean) => void;
  setCurrentViewingMode: (viewingMode: ViewingMode) => void;
  reloadCurrentDeviceById: (device: Device | undefined) => void;
}

export const useDeviceDetailsState = (
  deviceId: string | undefined,
  initialViewingMode: ViewingMode,
): IDeviceDetailsState => {
  const dispatch = useDispatch();

  const [currentDevice, setCurrentDevice] = useState<Device | undefined>();
  const [currentViewingMode, setCurrentViewingMode] =
    useState<ViewingMode>(initialViewingMode);
  const [currentDeviceId, setCurrentDeviceId] = useState<string | undefined>(
    deviceId,
  );
  const [isLoadingDevice, setIsLoadingDevice] = useState<boolean>(false);
  const [isSavingDevice, setIsSavingDevice] = useState<boolean>(false);

  const [triggerReadDevice, { data, isLoading: isLoadingReadDevice }] =
    useLazyReadDeviceQuery();

  const reloadCurrentDeviceById = (device: Device | undefined) => {
    if (device?.id !== undefined) {
      if (device.id !== currentDeviceId) {
        setCurrentDeviceId(device.id);
      } else {
        setCurrentDevice(device);
        resetForm(device);
        triggerReadDevice(device.id);
      }
    }
  };

  const defaultValues: Partial<Device> = {
    name: "",
    note: "",
    type: undefined,
    function: undefined,
    source: "",
    address: "",
    organisationUnitId: undefined,
    externalSystemId: "",
    externalId: "",
    commands: [],
    deviceMonitoring: undefined,
  };

  const form = useForm({
    defaultValues,
    mode: "onBlur",
  });

  const resetForm = (device?: Device) => form.reset(device ?? defaultValues);

  useEffect(() => {
    if (currentDeviceId !== undefined) {
      triggerReadDevice(currentDeviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentDeviceId]);

  useEffect(() => {
    if (data) {
      setCurrentDevice(data);
      resetForm(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setIsLoadingDevice(isLoadingReadDevice);
  }, [isLoadingReadDevice]);

  useEffect(() => {
    dispatch(
      setShouldShowConfirmation({
        shouldShowConfirmation: form.formState.isDirty,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.formState.isDirty]);

  return {
    currentDevice: currentDevice,
    currentViewingMode: currentViewingMode,
    isLoadingDevice,
    isSavingDevice,
    form,
    resetForm,
    setIsLoadingDevice,
    setIsSavingDevice,
    setCurrentViewingMode,
    reloadCurrentDeviceById,
  };
};
