import { Chip, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Paper from "@mui/material/Paper";
import { ReactElement, useState } from "react";
import KeyValuePair from "models/key-value-pair";
import OutsideAlerter from "components/outside-alerter/outside-alerter";
import "./filter-chip.component.scss";

interface IProps {
  options: KeyValuePair[];
  onOptionSelected: (key?: string) => void;
  placeHolder: string;
  initialSelectedOption?: KeyValuePair;
}

export default function FilterChip(props: Readonly<IProps>): ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  function handleOnChipClicked() {
    setIsOpen(!isOpen);
  }

  function handleOnMenuItemClicked(key: string, _: string) {
    setIsOpen(false);
    props.onOptionSelected(
      key !== props.initialSelectedOption?.key ? key : undefined,
    );
  }

  return (
    <OutsideAlerter functionToExecute={() => setIsOpen(false)}>
      <div className="filter-chip">
        <Chip
          data-testid={`filterChip${props.placeHolder}`}
          label={props.initialSelectedOption?.value ?? props.placeHolder}
          variant="outlined"
          clickable
          deleteIcon={<ArrowDropDownIcon />}
          onDelete={handleOnChipClicked}
          onClick={handleOnChipClicked}
          className={`${
            props.initialSelectedOption?.key !== undefined ? "active" : ""
          }`}
        />
        {isOpen && (
          <Paper elevation={1} className={"options"}>
            {props.options.map((option) => {
              return (
                <MenuItem
                  data-testid={`filterOption${option.key.replace(" ", "_")}`}
                  key={option.key}
                  value={option.key}
                  onClick={() =>
                    handleOnMenuItemClicked(option.key, option.value)
                  }
                  selected={props.initialSelectedOption?.key === option.key}
                >
                  {option.value}
                </MenuItem>
              );
            })}
          </Paper>
        )}
      </div>
    </OutsideAlerter>
  );
}
