import { Link as LinkIcon, LinkOff as LinkOffIcon } from "@mui/icons-material";
import { Button, Tooltip, Typography } from "@mui/material";
import PopUp from "components/pop-up/pop-up.component";
import OrganisationTreeNode, {
  findUpstreamParentBranch,
} from "features/organisation/domain/models/organisation-tree-node";
import OrganisationBreadCrumb from "features/organisation/views/organisation-bread-crumb/organisation-bread-crumb.component";
import OrganisationPicker from "features/organisation/views/organisation-picker/organisation-picker";
import { ReactElement, useEffect, useState } from "react";
import {
  ControllerRenderProps,
  FieldValues,
  useController,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./device-settings-organisation.scss";
import { useDeviceDetailsHook } from "features/device/device-details/hooks";
import { useOrganisationUnitTreeContext } from "features/organisation/providers/organisation-unit-tree-provider";
import Permission from "features/autorisation/domain/models/permission";
import { useAuth } from "features/authentication/providers/authentication.provider";

function DeviceSettingsOrganisation(): ReactElement {
  const { t } = useTranslation("deviceDetails");

  const { hasPermission } = useAuth();

  const [isOrganisationPickerOpen, setIsOrganisationPickerOpen] =
    useState(false);
  const [upstreamParentBranch, setUpstreamParentBranch] = useState<
    OrganisationTreeNode[]
  >([]);
  const [isLinkErrorPopupOpen, setIsLinkErrorPopupOpen] =
    useState<boolean>(false);

  const { field } = useController({ name: "organisationUnitId" });
  const { organisationUnitTree } = useOrganisationUnitTreeContext();

  const {
    currentViewingMode,
    currentDevice,
    currentExternalSystemId,
    allowUnlinking,
  } = useDeviceDetailsHook();

  function setOrganisationUnit(
    field: ControllerRenderProps<FieldValues, "organisationUnitId">,
    selectedNode: OrganisationTreeNode | null = null,
    upstreamBranch: OrganisationTreeNode[] | null = null,
  ): void {
    field.onChange(selectedNode?.id ?? null);
    setUpstreamParentBranch(upstreamBranch ?? []);
    setIsOrganisationPickerOpen(false);
  }

  useEffect(() => {
    if (organisationUnitTree && currentDevice?.organisationUnitId) {
      const upstreamBranch = findUpstreamParentBranch(
        organisationUnitTree,
        currentDevice.organisationUnitId,
        [],
      );
      setUpstreamParentBranch(upstreamBranch ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationUnitTree, currentDevice, currentViewingMode]);

  useEffect(() => {
    if (
      organisationUnitTree &&
      field.value &&
      findUpstreamParentBranch(organisationUnitTree, field.value, []) == null
    ) {
      if (!organisationUnitTree.some((org) => org.id === field.value)) {
        setOrganisationUnit(field, null, null);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationUnitTree, field]);

  return (
    <>
      {field.value ? (
        <div className="device-bread-crumb-container">
          <OrganisationBreadCrumb
            rootNodeId={field.value}
            upstreamParentBranch={upstreamParentBranch}
          />
        </div>
      ) : (
        <Typography variant="subtitle1">
          {t("settings.noOrganisationUnitLinkedLabel")}
        </Typography>
      )}
      {currentViewingMode !== "viewing" &&
        !allowUnlinking &&
        hasPermission(Permission.LinkDevice) &&
        (field.value ? (
          <Button
            variant="outlined"
            onClick={() => setOrganisationUnit(field)}
            startIcon={<LinkOffIcon />}
            data-testid="unlinkOrganisationButton"
          >
            {t("settings.unlinkOrganisationUnitButton")}
          </Button>
        ) : (
          <Tooltip
            title={
              currentExternalSystemId
                ? ""
                : t("settings.firstSelectExternalSystem")
            }
          >
            <span>
              <Button
                variant="outlined"
                onClick={() => setIsOrganisationPickerOpen(true)}
                startIcon={<LinkIcon />}
                data-testid="linkOrganisationButton"
                disabled={!currentExternalSystemId}
              >
                {t("settings.linkOrganisationUnitButton")}
              </Button>
            </span>
          </Tooltip>
        ))}

      <OrganisationPicker
        isOpen={isOrganisationPickerOpen}
        onClose={() => setIsOrganisationPickerOpen(false)}
        onOrganisationUnitSelected={(selectedNode, upstreamParentBranch) =>
          setOrganisationUnit(field, selectedNode, upstreamParentBranch)
        }
      />

      <PopUp
        isOpen={isLinkErrorPopupOpen}
        title={t("linkDeviceError.title", {
          deviceName: currentDevice?.name,
        })}
        body={t("linkDeviceError.bodyText", {
          deviceName: currentDevice?.name,
        })}
        primaryButtonText={t("linkDeviceError.confirmButton")}
        handleOnClose={() => setIsLinkErrorPopupOpen(false)}
        primaryButtonAction={() => setIsLinkErrorPopupOpen(false)}
      />
    </>
  );
}

export default DeviceSettingsOrganisation;
