import { useTranslation } from "react-i18next";
import "./external-system-information.component.scss";
import { Switch, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { dateFormatOptions } from "utils/date-utils";
import { ReactElement, useEffect } from "react";
import moment from "moment";
import { ExternalSystemType } from "features/external-system/domain/models/external-system-type";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import useExternalSystemValidation from "features/external-system/hooks/external-system-validation-hook";
import ExternalSystemMonitoringAlert from "../components/external-system-monitoring-alert";
import ExternalSystem from "features/external-system/domain/models/external-system";
import { ExternalSystemStatus } from "features/external-system/domain/models/external-system-status";

function ExternalSystemInformation(): ReactElement {
  const { t } = useTranslation("externalSystem");

  const {
    formState: { errors },
    control,
    watch,
  } = useFormContext();

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const { validateName, validateVirtualIpAddress } =
    useExternalSystemValidation();

  const watchShouldCreateDevicesAutomatically = watch(
    "shouldCreateDevicesAutomatically",
  );

  const watchSystemType = watch("type");

  const registerOptions = {
    name: {
      required: t("details.information.requiredHelperText"),
      validate: validateName,
    },
    ipAddress: {
      required: t("details.information.requiredHelperText"),
      validate: validateVirtualIpAddress,
    },
  };

  useEffect(() => {
    externalSystemDetailsHook.viewingMode === "creation" &&
      externalSystemDetailsHook.resetOnSystemTypeUpdate(watchSystemType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchSystemType]);

  return (
    <div
      className={`external-system-information${
        externalSystemDetailsHook.viewingMode === "viewing" ? " viewing" : ""
      }`}
    >
      {externalSystemDetailsHook.viewingMode === "editing" ||
      externalSystemDetailsHook.viewingMode === "creation" ? (
        <>
          <Typography variant="h2" className="details-title">
            {externalSystemDetailsHook.viewingMode === "creation" &&
              t("details.createSystemTitle")}
            {externalSystemDetailsHook.viewingMode === "editing" &&
              t("details.editSystemTitle")}
          </Typography>

          {externalSystemDetailsHook.currentSelectedExternalSystem?.status ===
            ExternalSystemStatus.offline && (
            <div className="monitoring-container">
              <ExternalSystemMonitoringAlert.Offline />
            </div>
          )}

          <Controller
            name="name"
            control={control}
            rules={registerOptions.name}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{ "data-testid": "nameInput", maxLength: 255 }}
                id="nameInput"
                label={t("details.information.nameLabel")}
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message?.toString()}
                className="information-item-textfield"
              />
            )}
          />
          {(watchSystemType === ExternalSystemType.IoTEdge ||
            watchSystemType === ExternalSystemType.IoTEdgeCluster ||
            watchSystemType === ExternalSystemType.Viewpoint) && (
            <Controller
              name="ipAddress"
              control={control}
              rules={registerOptions.ipAddress}
              render={({ field }) => (
                <TextField
                  {...field}
                  inputProps={{
                    "data-testid": "ipAddressInput",
                    maxLength: 15,
                  }}
                  id="ipAddressInput"
                  label={t("details.information.ipAddressLabel")}
                  variant="outlined"
                  error={!!errors.ipAddress}
                  helperText={errors.ipAddress?.message?.toString()}
                  className="information-item-textfield"
                />
              )}
            />
          )}
          <Controller
            name="note"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  "data-testid": "noteInput",
                  maxLength: 255,
                }}
                id="noteInput"
                label={t("details.information.noteLabel")}
                variant="outlined"
                error={!!errors.note}
                helperText={errors.note?.message?.toString()}
                multiline
                minRows={8}
                maxRows={12}
                className="information-item-textfield"
              />
            )}
          />
          {watchSystemType !== ExternalSystemType.NFC &&
            watchSystemType !== ExternalSystemType.ESPA && (
              <div className="automaticDeviceCreationRow">
                <Typography variant="subtitle1">
                  {t("details.information.automaticDeviceCreation")}
                </Typography>
                <Controller
                  name="shouldCreateDevicesAutomatically"
                  control={control}
                  render={({ field }) => (
                    <Switch {...field} type="checkbox" checked={field.value} />
                  )}
                />
              </div>
            )}
        </>
      ) : (
        <>
          <Typography
            variant="h2"
            className="external-system-name-title"
            data-testid="externalSystemInformationName"
          >
            {externalSystemDetailsHook.currentSelectedExternalSystem?.name}
          </Typography>

          {monitoringAlert()}

          {externalSystemDetailsHook.currentSelectedExternalSystem
            ?.ipAddress && (
            <div className="information-item">
              <Typography variant="h5">
                {t("details.information.ipAddressLabel")}
              </Typography>
              <Typography
                variant="subtitle1"
                data-testid="externalSystemInformationIpAddress"
              >
                {
                  externalSystemDetailsHook.currentSelectedExternalSystem
                    ?.ipAddress
                }
              </Typography>
            </div>
          )}

          {externalSystemDetailsHook.currentSelectedExternalSystem?.note && (
            <div className="information-item">
              <Typography variant="h5">
                {t("details.information.noteLabel")}
              </Typography>
              <Typography
                variant="subtitle1"
                data-testid="externalSystemInformationDescription"
              >
                {externalSystemDetailsHook.currentSelectedExternalSystem?.note}
              </Typography>
            </div>
          )}

          {watchShouldCreateDevicesAutomatically && (
            <Typography variant="subtitle1">
              {t("details.information.automaticDeviceCreationActiveUntill", {
                createDevicesAutomaticallySince: moment(
                  externalSystemDetailsHook.currentSelectedExternalSystem
                    ?.createDevicesAutomaticallySince,
                )
                  ?.add(1, "hour")
                  .format("HH:mm"),
              })}
            </Typography>
          )}
        </>
      )}
      {watchSystemType !== ExternalSystemType.NFC &&
        (externalSystemDetailsHook.viewingMode === "viewing" ||
          externalSystemDetailsHook.viewingMode === "editing") && (
          <div className="information-item">
            <Typography variant="h5">
              {t("details.information.statusLabel")}
            </Typography>
            <Typography
              variant="subtitle1"
              data-testid="externalSystemInformationLastCommunicationDate"
            >
              {watchSystemType === ExternalSystemType.IoTEdgeCluster &&
              externalSystemDetailsHook.currentSelectedExternalSystem
                ?.childExternalSystems
                ? externalSystemDetailsHook.currentSelectedExternalSystem?.childExternalSystems.map(
                    (system, index) =>
                      mapStatusChildExternalSystem(
                        system,
                        index <
                          externalSystemDetailsHook.currentSelectedExternalSystem!
                            .childExternalSystems!.length -
                            1
                          ? "status-item"
                          : "last-status-item",
                      ),
                  )
                : showLastCommunicationDate(
                    externalSystemDetailsHook.currentSelectedExternalSystem
                      ?.lastCommunicationDate,
                  )}
            </Typography>
          </div>
        )}
    </div>
  );

  function monitoringAlert(): ReactElement {
    let externalSystem =
      externalSystemDetailsHook.currentSelectedExternalSystem;

    if (externalSystem?.status === ExternalSystemStatus.offline) {
      return (
        <div className="monitoring-container">
          <ExternalSystemMonitoringAlert.Offline />
        </div>
      );
    } else if (
      externalSystem?.status === ExternalSystemStatus.passiveOfflineOnly
    ) {
      const offlineChildExternalSystem =
        externalSystem?.childExternalSystems?.find(
          (item) => item.status === ExternalSystemStatus.offline,
        );
      if (offlineChildExternalSystem !== undefined) {
        return (
          <div className="monitoring-container">
            <ExternalSystemMonitoringAlert.PassiveExternalSystemOffline
              systemName={offlineChildExternalSystem.name}
              isActiveInCluster={offlineChildExternalSystem.isActiveInCluster}
            />
          </div>
        );
      }
    }
    return <></>;
  }

  function mapStatusChildExternalSystem(
    externalSystem: ExternalSystem,
    className: string,
  ) {
    return (
      <div key={externalSystem.id} className={className}>
        <Typography variant="subtitle1">{externalSystem.name}</Typography>
        <Typography variant="subtitle1">
          {externalSystem.isActiveInCluster
            ? t("details.information.active")
            : t("details.information.passive")}
        </Typography>
        <Typography variant="subtitle1">
          {showLastCommunicationDate(externalSystem.lastCommunicationDate)}
        </Typography>
      </div>
    );
  }

  function showLastCommunicationDate(
    lastCommunicationDate: Date | undefined,
  ): ReactElement {
    return lastCommunicationDate
      ? t("details.information.statusLastCommunicationDateLabel", {
          lastCommunicationDate: lastCommunicationDate.toLocaleString(
            "nl-NL",
            dateFormatOptions.dateTime,
          ),
        })
      : t("details.information.statusUnknownLabel");
  }
}

export default ExternalSystemInformation;
