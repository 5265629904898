import { ReactElement } from "react";
import "./history-details-header.component.scss";
import { useHistoryContextProvider } from "features/history/providers/history-provider";
import { Divider, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close, RefreshRounded } from "@mui/icons-material";

function HistoryDetailsHeader(): ReactElement {
  const { t } = useTranslation("history");

  const { setCurrentSelectedItem, refresh } = useHistoryContextProvider();

  return (
    <div className="history-details-header">
      <Typography variant="h3">{t("details.title")}</Typography>
      <div className="buttons">
        <IconButton className="refresh-button" onClick={refresh}>
          <RefreshRounded />
        </IconButton>
        <Divider orientation="vertical" flexItem />
        <IconButton
          aria-label="close"
          className="close-button"
          onClick={() => setCurrentSelectedItem(undefined)}
          data-testid="externalSystemDetailsPopupCloseButton"
        >
          <Close />
        </IconButton>
      </div>
    </div>
  );
}

export default HistoryDetailsHeader;
