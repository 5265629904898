import { EmergencyAlarmActivationMethod } from "./emergency-alarm-activation-method";
import { EmergencyAlarmDeviceTriggerAction } from "./emergency-alarm-device-trigger-action";

export const preAlarmDurationOptions = [2, 4, 6, 8, 10];

export default interface EmergencyAlarmSetting {
  id: string;
  name?: string;
  organisationUnitId: string | null;
  emergencyAlarmEnabled: boolean;
  activationMethod: EmergencyAlarmActivationMethod;
  deviceTriggerAction: EmergencyAlarmDeviceTriggerAction;
  preAlarmEnabled: boolean;
  preAlarmDurationS: number;
  soundEnabled: boolean;
  isDefaultTemplateSetting: boolean;
}
