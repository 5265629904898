import { Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExternalSystemSettingsOrganisation from "./external-system-settings-organisation";
import ExternalSystemSettingsType from "./external-system-settings-type";
import "./external-system-settings.scss";
import { ExternalSystemType } from "features/external-system/domain/models/external-system-type";
import ExternalSystemSettingsViewpointClient from "features/external-system/views/external-system-details/external-system-settings/external-system-settings-clients/external-system-settings-viewpoint-client";
import ExternalSystemSettingsIoTEdgeClient from "features/external-system/views/external-system-details/external-system-settings/external-system-settings-clients/external-system-settings-iot-edge-client";
import { ReactElement } from "react";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import ExternalSystemSettingsIoTEdgeClusterClient from "./external-system-settings-clients/external-system-settings-iot-edge-cluster-client";
import { useFormContext } from "react-hook-form";
import ExternalSystemSettingsOfflinePortalCredentials from "./external-system-settings-offline-portal-credentials";
import ExternalSystemSettingsKadex from "./external-system-settings-kadex";
import ExternalSystemSettingsEspaClient from "./external-system-settings-clients/external-system-settings-espa-client";

function ExternalSystemSettings(): ReactElement {
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();

  const { watch } = useFormContext();

  const watchSystemType = watch("type");

  return (
    <div
      className={`external-system-settings${
        externalSystemDetailsHook.viewingMode === "viewing" ? " viewing" : ""
      }`}
    >
      <div
        className="settings-item"
        data-testid="externalSystemOrganisationContainer"
      >
        <Typography variant="h5">
          {t("details.settings.organisationUnitLabel")}
        </Typography>
        <div className="settings-item-content">
          <ExternalSystemSettingsOrganisation />
        </div>
      </div>
      <Divider />
      <div className="settings-item">
        <Typography variant="h5">{t("details.settings.typeLabel")}</Typography>
        <div className="settings-item-content">
          <ExternalSystemSettingsType />
        </div>
      </div>
      {watchSystemType !== ExternalSystemType.NFC && (
        <>
          <Divider />
          <div className="settings-item">
            <Typography variant="h5">
              {t("details.settings.clientSettingsLabel")}
            </Typography>
            <div className="settings-item-content">
              {externalSystemDetailsHook.currentSelectedExternalSystem &&
                externalSystemDetailsHook.currentSelectedExternalSystem.type ===
                  ExternalSystemType.Viewpoint && (
                  <ExternalSystemSettingsViewpointClient />
                )}
              {externalSystemDetailsHook.currentSelectedExternalSystem &&
                externalSystemDetailsHook.currentSelectedExternalSystem.type ===
                  ExternalSystemType.IoTEdge && (
                  <ExternalSystemSettingsIoTEdgeClient />
                )}
              {watchSystemType === ExternalSystemType.IoTEdgeCluster && (
                <ExternalSystemSettingsIoTEdgeClusterClient />
              )}
              {watchSystemType === ExternalSystemType.ESPA && (
                <ExternalSystemSettingsEspaClient />
              )}
            </div>
          </div>
        </>
      )}
      {(watchSystemType === ExternalSystemType.IoTEdge ||
        watchSystemType === ExternalSystemType.IoTEdgeCluster) && (
        <>
          <Divider />
          <div className="settings-item">
            <Typography variant="h5">
              {t("details.settings.offlinePortalCredentialsLabel")}
            </Typography>
            <div className="settings-item-content">
              <ExternalSystemSettingsOfflinePortalCredentials />
            </div>
          </div>
          <Divider />
          <div className="settings-item">
            <Typography variant="h5">
              {t("details.settings.kadexLabel")}
            </Typography>
            <div className="settings-item-content">
              <ExternalSystemSettingsKadex />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ExternalSystemSettings;
