import { CssBaseline } from "@mui/material";
import { User } from "oidc-client";
import { ReactElement, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Menu from "features/menu/views/menu.component";
import { useAuth } from "features/authentication/providers/authentication.provider";
import "./layout-with-authentication.scss";
import authenticationService from "features/authentication/services/authentication.service";
import { SessionStorageKeys } from "features/authentication/constants/session-storage-keys";

function LayoutWithAuthentication(): ReactElement {
  const { user } = useAuth();
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    IsUserAuthenticated(user),
  );

  useEffect(() => {
    const isUserAuthenticated = IsUserAuthenticated(user);
    setIsAuthenticated(isUserAuthenticated);

    if (!isUserAuthenticated) {
      sessionStorage.setItem(
        SessionStorageKeys.REDIRECT_TO,
        window.location.pathname,
      );
      authenticationService.login("", "none", "");
    }
  }, [pathname, user]);

  if (!isAuthenticated) {
    return <></>;
  }

  return (
    <div className="layout-container">
      <CssBaseline />
      <Menu />
      <div className="layout-body">
        <Outlet />
      </div>
    </div>
  );
}

function IsUserAuthenticated(user: User | null) {
  return user != null && user.expires_at > new Date().getTime() / 1000;
}

export default LayoutWithAuthentication;
