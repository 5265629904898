import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import "./external-system-settings-kadex.scss";
import { ReactElement, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useExternalSystemsContextProvider } from "features/external-system/context/external-systems-provider";
import useExternalSystemValidation from "features/external-system/hooks/external-system-validation-hook";

function ExternalSystemSettingsKadex(): ReactElement {
  const {
    formState: { errors },
    control,
  } = useFormContext();

  const { validateIpAddress } = useExternalSystemValidation();

  const { t } = useTranslation("externalSystem");
  const { externalSystemDetailsHook } = useExternalSystemsContextProvider();
  const [showPassword, setShowPassword] = useState(false);

  const registerOptions = {
    kadexIpAddress: {
      validate: validateIpAddress,
    },
  };

  function getDottedPassword(): string {
    return "*".repeat(10);
  }

  return (
    <>
      {externalSystemDetailsHook.viewingMode === "viewing" && (
        <div className="external-system-kadex viewing">
          <Typography variant="subtitle1" data-testid="ipAddressLabel">
            {t("details.settings.kadex.ipAddress")}:{" "}
            {!externalSystemDetailsHook.currentSelectedExternalSystem
              ?.kadexIpAddress ||
            externalSystemDetailsHook.currentSelectedExternalSystem
              ?.kadexIpAddress === ""
              ? t(`details.settings.kadex.noIpAddressDefined`)
              : externalSystemDetailsHook.currentSelectedExternalSystem
                  ?.kadexIpAddress}
          </Typography>
          <Typography variant="subtitle1" data-testid="usernameLabel">
            {t("details.settings.kadex.username")}:{" "}
            {!externalSystemDetailsHook.currentSelectedExternalSystem
              ?.kadexUsername ||
            externalSystemDetailsHook.currentSelectedExternalSystem
              ?.kadexUsername === ""
              ? t(`details.settings.kadex.noUsernameDefined`)
              : externalSystemDetailsHook.currentSelectedExternalSystem
                  ?.kadexUsername}
          </Typography>
          <Typography variant="subtitle1" data-testid="passwordLabel">
            {t("details.settings.kadex.password")}:{" "}
            {!externalSystemDetailsHook.currentSelectedExternalSystem
              ?.kadexPassword ||
            externalSystemDetailsHook.currentSelectedExternalSystem
              ?.kadexPassword === ""
              ? t(`details.settings.kadex.noPasswordDefined`)
              : getDottedPassword()}
          </Typography>
        </div>
      )}
      {externalSystemDetailsHook.viewingMode !== "viewing" && (
        <div className="external-system-kadex">
          <Controller
            name={"kadexIpAddress"}
            control={control}
            rules={registerOptions.kadexIpAddress}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  "data-testid": `kadexIpAddress`,
                }}
                id="kadexUsername"
                label={t("details.settings.kadex.ipAddress")}
                variant="outlined"
                className="form-field-credentials"
                error={!!errors.kadexIpAddress}
                helperText={errors.kadexIpAddress?.message?.toString()}
              />
            )}
          />
          <Controller
            name={"kadexUsername"}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                inputProps={{
                  "data-testid": `kadexUsername`,
                }}
                id="kadexUsername"
                label={t("details.settings.kadex.username")}
                variant="outlined"
                className="form-field-credentials"
              />
            )}
          />
          <Controller
            name={"kadexPassword"}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type={showPassword ? "text" : "password"}
                inputProps={{
                  "data-testid": `kadexPassword`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                id="kadexPassword"
                label={t("details.settings.kadex.password")}
                variant="outlined"
                className="form-field-credentials"
              />
            )}
          />
        </div>
      )}
    </>
  );
}

export default ExternalSystemSettingsKadex;
