import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import TemporaryAccess from "../domain/models/temporary-access";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem, TableOverflowMenuItem } from "components/table/table";
import { NestedKeyof } from "utils/nested-keyof-utils";
import { Tooltip, Typography, Zoom } from "@mui/material";
import BreadCrumb from "components/bread-crumb/bread-crumb.component";
import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { TemporaryAccesStatus } from "../domain/models/temporary-access-status";
import moment from "moment";
import "./temporary-access-table.component.scss";
import { useTemporaryAccessContextProvider } from "../providers/temporary-access-provider";
import { splitFullNameToInitials } from "utils/string-utils";
import { TemporaryAccessType } from "../domain/models/temporary-access-type";

function TemporaryAccessTable(): ReactElement {
  const { t } = useTranslation("temporaryAccess");

  const {
    readTemporaryAccessData,
    readTemporaryAccessIsFetching,
    readTemporaryAccessIsSuccess,
    revokeTemporaryAccess,
    temporaryAccessFilterHook,
    selectTemporaryUser,
  } = useTemporaryAccessContextProvider();

  const overflowMenuOptions = [
    {
      label: t("temporaryAccessTable.action.read"),
      action: (temporaryAccess: TemporaryAccess) =>
        selectTemporaryUser(temporaryAccess.id, "viewing"),
      isVisible: (temporaryAccess: TemporaryAccess) =>
        temporaryAccess.status === TemporaryAccesStatus.Active &&
        temporaryAccess.type === TemporaryAccessType.User,
    },
    {
      label: t("temporaryAccessTable.action.revoke"),
      action: (temporaryAccess: TemporaryAccess) =>
        revokeTemporaryAccess(temporaryAccess),
    },
  ] as TableOverflowMenuItem<TemporaryAccess>[];

  const columns: ColumnDefinition<
    TemporaryAccess,
    NestedKeyof<TemporaryAccess>
  >[] = [
    {
      key: "fullName",
      label: t("temporaryAccessTable.column.name"),
      renderCustomContentProvider: (item) => (
        <Typography variant="subtitle1">{item.fullName}</Typography>
      ),
      disableSort: true,
    },
    {
      key: "type",
      label: t("temporaryAccessTable.column.type"),
      renderCustomContentProvider: (item) => (
        <Typography variant="subtitle1">{t(`type.${item.type}`)}</Typography>
      ),
      disableSort: true,
    },
    {
      key: "organisationUnitWithAncestors.length",
      label: t("temporaryAccessTable.column.organisationUnit"),
      renderCustomContentProvider: (item) => (
        <BreadCrumb
          showEllipsis={item.organisationUnitWithAncestors.length > 2}
          allElementNames={item.organisationUnitWithAncestors.map(
            (organisationUnit) => organisationUnit.name,
          )}
          data-testid={`temporary-users-breadcrumb-child-${item.id}`}
        >
          {item.organisationUnitWithAncestors.map((organisationUnit, index) => {
            return (
              <Typography
                data-testid={`temporary-users-breadcrumb-child-${index}`}
                key={`temporary-users-breadcrumb-child-${organisationUnit.name}`}
                variant="caption"
                className="bread-crumb-item"
              >
                {organisationUnit.name}
              </Typography>
            );
          })}
        </BreadCrumb>
      ),
      disableSort: true,
    },
    {
      key: "startsOn",
      label: t("temporaryAccessTable.column.accessStartsOn"),
      renderCustomContentProvider: (item) => (
        <>{moment(item.startsOn).format("DD-MM-YYYY").toString()}</>
      ),
      disableSort: true,
    },
    {
      key: "endsOn",
      label: t("temporaryAccessTable.column.accessEndsOn"),
      renderCustomContentProvider: (item) => (
        <>
          {item.endsOn && moment(item.endsOn).format("DD-MM-YYYY").toString()}
        </>
      ),
      disableSort: true,
    },
    {
      key: "temporaryChannelAccessDetails",
      label: t("temporaryAccessTable.column.reason"),
      renderCustomContentProvider: (item) => (
        <>
          {item.temporaryChannelAccessDetails !== null &&
            item.temporaryChannelAccessDetails?.reasonType && (
              <Tooltip
                title={item.temporaryChannelAccessDetails?.reasonText}
                followCursor
                TransitionComponent={Zoom}
              >
                <Typography variant="subtitle1">
                  {t(
                    `reasonType.${item.temporaryChannelAccessDetails?.reasonType}`,
                  )}
                </Typography>
              </Tooltip>
            )}
        </>
      ),
      disableSort: true,
    },
    {
      key: "temporaryUserDetails",
      label: t("temporaryAccessTable.column.invitedBy"),
      renderCustomContentProvider: (item) => (
        <>
          {item.temporaryUserDetails !== null &&
            item.temporaryUserDetails?.createdBy !== "" && (
              <Tooltip
                title={item.temporaryUserDetails?.createdBy}
                followCursor
                TransitionComponent={Zoom}
              >
                <div className="avatar-container">
                  <Typography variant="overline">
                    {splitFullNameToInitials(
                      item.temporaryUserDetails?.createdBy,
                    )}
                  </Typography>
                </div>
              </Tooltip>
            )}
        </>
      ),
      disableSort: true,
    },
    {
      key: "status",
      label: "",
      renderCustomContentProvider: (item) => (
        <Tooltip
          title={
            item.status === TemporaryAccesStatus.Revoked
              ? t("revokedBy", {
                  username: item.revokedBy,
                  date: moment(item.revokedOn)
                    .format("DD-MM-YYYY HH:mm")
                    .toString(),
                })
              : ""
          }
          followCursor
          TransitionComponent={Zoom}
        >
          <div
            className={`temporary-users-status ${
              item.status === TemporaryAccesStatus.Active ? "active" : ""
            }`}
          >
            {t(`status.${item.status}`)}
          </div>
        </Tooltip>
      ),
      disableSort: true,
    },
  ];

  return (
    <>
      {readTemporaryAccessIsFetching && <LoadingIndicator />}
      {readTemporaryAccessIsSuccess && readTemporaryAccessData && (
        <Table
          data={readTemporaryAccessData.temporaryAccess.map<
            IRowItem<TemporaryAccess>
          >((temporaryAccess) => ({
            isHighlighted:
              temporaryAccess.status === TemporaryAccesStatus.Active,
            hideOverflowMenuOptions:
              temporaryAccess.status !== TemporaryAccesStatus.Active,
            data: temporaryAccess,
            isClickable:
              temporaryAccess.status === TemporaryAccesStatus.Active &&
              temporaryAccess.type === TemporaryAccessType.User,
          }))}
          columns={columns}
          enablePagination={true}
          count={readTemporaryAccessData.total}
          onPageChanged={
            temporaryAccessFilterHook.tableHook.handleOnPageChanged
          }
          rowsPerPage={temporaryAccessFilterHook.tableHook.currentRowsPerPage}
          page={temporaryAccessFilterHook.tableHook.currentPage}
          onRowsPerPageChanged={
            temporaryAccessFilterHook.tableHook.handleOnRowsPerPageChanged
          }
          rowIdentifier={(item) => item.id}
          overflowMenuOptions={overflowMenuOptions}
          onItemClick={(temporaryAccess) =>
            selectTemporaryUser(temporaryAccess.id, "viewing")
          }
        />
      )}
    </>
  );
}

export default TemporaryAccessTable;
