import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDeviceDetailsHook } from "../../hooks";
import { MenuItem, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ViewingModeController from "components/viewModeController/viewing-mode-controller";
import SelectField from "components/formFields/select-field.component";
import { DeviceFunction } from "features/device/models/device-function";

function DeviceSettingsFunction(): ReactElement {
  const { t } = useTranslation("deviceDetails");

  const { currentViewingMode, currentDevice } = useDeviceDetailsHook();
  const { control } = useFormContext();

  const deviceFunctionOptions = [
    <MenuItem
      key={`device-function-present-and-accept-alarms`}
      value={DeviceFunction.PresentAndAcceptAlarms}
    >
      {t(`deviceFunctions.${DeviceFunction.PresentAndAcceptAlarms}`)}
    </MenuItem>,
    <MenuItem
      key={`device-function-absent-and-handle-alarms`}
      value={DeviceFunction.AbsentAndHandleAlarms}
    >
      {t(`deviceFunctions.${DeviceFunction.AbsentAndHandleAlarms}`)}
    </MenuItem>,
  ];

  return (
    <>
      {(currentViewingMode === "viewing" ||
        (currentViewingMode === "editing" &&
          currentDevice?.importDate != null)) && (
        <Typography variant="subtitle1">
          {currentDevice
            ? t(`deviceFunctions.${currentDevice.function}`)
            : t(`deviceFunctions.${DeviceFunction.PresentAndAcceptAlarms}`)}
        </Typography>
      )}

      {(currentViewingMode === "creation" ||
        currentViewingMode === "editing") &&
        currentDevice?.importDate == null && (
          <ViewingModeController
            viewingMode={currentViewingMode}
            name={`function`}
            defaultValue={DeviceFunction.PresentAndAcceptAlarms}
            control={control}
            label={t("info.functionLabel")}
            render={({ field }) => (
              <SelectField
                {...field}
                variant="outlined"
                label={t("info.functionLabel")}
                className="select-field"
                value={`${
                  field.value ?? DeviceFunction.PresentAndAcceptAlarms
                }`}
              >
                {deviceFunctionOptions.map((menuItem) => menuItem)}
              </SelectField>
            )}
          />
        )}
    </>
  );
}

export default DeviceSettingsFunction;
