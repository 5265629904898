import { Typography } from "@mui/material";
import BreadCrumb from "components/bread-crumb/bread-crumb.component";
import OrganisationTreeNode from "features/organisation/domain/models/organisation-tree-node";
import PinDropOutlinedIcon from "@mui/icons-material/PinDropOutlined";

interface IProps {
  rootNodeId?: string;
  upstreamParentBranch: OrganisationTreeNode[];
  showIcon?: boolean;
}

export default function OrganisationBreadCrumb(props: Readonly<IProps>) {
  const breadCrumbs = fillBreadCrumbs([], props.rootNodeId);

  function fillBreadCrumbs(breadCrumbs: string[], parentId?: string): string[] {
    const parent = props.upstreamParentBranch.find(
      (node) => node.id === parentId,
    );

    if (parent) {
      breadCrumbs.unshift(parent.name);
      return fillBreadCrumbs(breadCrumbs, parent.parentId);
    }

    return breadCrumbs;
  }

  const startIcon = props.showIcon ? <PinDropOutlinedIcon /> : undefined;

  return (
    <BreadCrumb
      showEllipsis={breadCrumbs.length > 4}
      startIcon={startIcon}
      allElementNames={breadCrumbs}
      data-testid="organisationBreadCrumb"
    >
      {breadCrumbs.map((parentTitle: string, index: number) => {
        return (
          <Typography
            data-testid={`breadcrumb-child-${index}`}
            key={`Breadcrumb_Child_${parentTitle}`}
            variant="subtitle1"
          >
            {parentTitle}
          </Typography>
        );
      })}
    </BreadCrumb>
  );
}
