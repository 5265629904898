import ExternalSystem from "../domain/models/external-system";
import { useEffect, useState } from "react";
import { FilterValueType } from "../domain/models/external-system-filter-value";
import ExternalSystemFilter from "../domain/models/external-system-filter";
import useTableHook, { ITableHook } from "hooks/table-hook";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { useSessionStorage } from "usehooks-ts";
import { ReadExternalSystemsQuery } from "../domain/models/read-external-systems-query";
import { useLazyGetMonitorFiltersQuery } from "../domain/reducers/external-system.reducer";
import { MonitoringType } from "components/monitoring/monitoring-type";
import ExternalSystemMonitoringFilter from "../domain/models/external-system-monitoring-filter";

export interface IExternalSystemFiltersHook {
  activeFilters: ExternalSystemFilter[];
  searchBarValue: string;
  selectedOrganisationUnits: SelectedOrganisationTreeNode[];
  changeSelectedOrganisationUnits: (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) => void;

  submitSearch: (query: string) => void;
  selectOption: (filterValueType: FilterValueType, keys: string[]) => void;
  clearFilters: () => void;

  monitorFilters: ExternalSystemMonitoringFilter[];
  monitorFiltersIsSuccess: boolean;
  getMonitorFilter: (
    monitoringType: MonitoringType,
  ) => ExternalSystemMonitoringFilter;
  loadMonitorFilters: () => void;
  selectMonitorFilter: (
    monitoringType: MonitoringType,
    selected: boolean,
  ) => void;
  isActiveMonitorFilter: (monitoringType: MonitoringType) => boolean;

  readExternalSystemsQuery: ReadExternalSystemsQuery;

  table: ITableHook<ExternalSystem>;
}

const useExternalSystemFilters = (): IExternalSystemFiltersHook => {
  const [activeFilters, setActiveFilters] = useState<ExternalSystemFilter[]>(
    [],
  );
  const [monitorFilters, setMonitorFilters] = useState<
    ExternalSystemMonitoringFilter[]
  >([]);
  const [readExternalSystemsQuery, setReadExternalSystemsQuery] =
    useState<ReadExternalSystemsQuery>({
      filters: Array<ExternalSystemFilter>(),
    } as ReadExternalSystemsQuery);

  const table = useTableHook<ExternalSystem>({
    defaultSort: {
      isAscending: true,
      property: "name",
    },
    sessionStorageKey: "external-system-sort",
  });

  const [
    triggerGetMonitorFiltersQuery,
    { data: monitorFiltersData, isSuccess: monitorFiltersIsSuccess },
  ] = useLazyGetMonitorFiltersQuery();

  const [
    externalSystemFiltersFromSessionStorage,
    setExternalSystemFiltersFromSessionStorage,
  ] = useSessionStorage<ExternalSystemFilter[]>("external-system-filters", []);
  const [
    selectedOrganisationUnitsFromSessionStorage,
    setSelectedOrganisationUnitsFromSessionStorage,
  ] = useSessionStorage<SelectedOrganisationTreeNode[]>(
    "selected-organisation-units-external-system-filters",
    [],
  );
  const [searchBarValueFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>("search-bar-value-external-system-filters", "");

  useEffect(() => {
    triggerGetMonitorFiltersQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMonitorFilters(monitorFiltersData ?? []);
  }, [monitorFiltersData]);

  useEffect(() => {
    buildAndSaveQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    table.currentPage,
    table.currentRowsPerPage,
    table.sortFromSessionStorage,
    externalSystemFiltersFromSessionStorage,
    searchBarValueFromSessionStorage,
  ]);

  const submitSearch = (query: string) => {
    setSearchBarFromSessionStorage(query);
    table.resetPaging();
  };

  const selectOption = (filterValueType: FilterValueType, keys: string[]) => {
    let updatedFiltersList = [...readExternalSystemsQuery.filters];
    if (
      updatedFiltersList.findIndex(
        (x) => x.filterValueType === filterValueType,
      ) !== -1
    ) {
      updatedFiltersList[
        updatedFiltersList.findIndex(
          (x) => x.filterValueType === filterValueType,
        )
      ] = {
        filterValueType: filterValueType,
        values: keys,
      };
    } else {
      updatedFiltersList.push({
        filterValueType: filterValueType,
        values: keys,
      });
    }

    updatedFiltersList = updatedFiltersList.filter((x) => x.values.length > 0);

    setExternalSystemFiltersFromSessionStorage(updatedFiltersList);

    table.resetPaging();
  };

  const changeSelectedOrganisationUnits = (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) =>
    setSelectedOrganisationUnitsFromSessionStorage(selectedOrganisationUnits);

  const clearFilters = () => {
    setActiveFilters([]);
    setExternalSystemFiltersFromSessionStorage([]);
    setSelectedOrganisationUnitsFromSessionStorage([]);
    setReadExternalSystemsQuery((prevState) => ({
      ...prevState,
      filters: [],
    }));

    table.resetPaging();
  };

  const getMonitorFilter = (monitoringType: MonitoringType) => {
    let filter = monitorFilters.find(
      (filter) => filter.monitoringFilterType === monitoringType,
    );
    if (filter) {
      return filter;
    }

    return {
      monitoringFilterType: MonitoringType.All,
      count: monitorFilters.reduce((total, filter) => total + filter.count, 0),
    } as ExternalSystemMonitoringFilter;
  };

  const isActiveMonitorFilter = (monitoringType: MonitoringType) => {
    let filter = activeFilters.find(
      (x) => x.filterValueType === FilterValueType.Monitor,
    );
    if (!filter) {
      return false;
    }

    return filter.values.find((x) => x === monitoringType) !== undefined;
  };

  const loadMonitorFilters = () => {
    triggerGetMonitorFiltersQuery();
  };

  const selectMonitorFilter = (
    monitoringType: MonitoringType,
    selected: boolean,
  ) => {
    let activeMonitorFilters = [
      ...(activeFilters.find(
        (x) => x.filterValueType === FilterValueType.Monitor,
      )?.values ?? []),
    ];

    if (
      selected &&
      activeMonitorFilters.find((x) => x === monitoringType) === undefined
    ) {
      activeMonitorFilters = [...activeMonitorFilters, monitoringType];
    } else if (
      !selected &&
      activeMonitorFilters.find((x) => x === monitoringType) !== undefined
    ) {
      let activeMonitorFilterIndex = activeMonitorFilters.findIndex(
        (x) => x === monitoringType,
      );
      if (activeMonitorFilterIndex >= 0) {
        activeMonitorFilters.splice(activeMonitorFilterIndex, 1);
      }
    }

    selectOption(FilterValueType.Monitor, activeMonitorFilters);
    loadMonitorFilters();
  };

  function buildAndSaveQuery() {
    setActiveFilters(externalSystemFiltersFromSessionStorage);
    setReadExternalSystemsQuery((prevState) => ({
      ...prevState,
      pagination: table.currentPagination[0],
      sort: table.sortFromSessionStorage ?? prevState.sort,
      filters: externalSystemFiltersFromSessionStorage,
      searchQuery: searchBarValueFromSessionStorage,
    }));
  }

  return {
    activeFilters,
    searchBarValue: searchBarValueFromSessionStorage,
    selectedOrganisationUnits: selectedOrganisationUnitsFromSessionStorage,
    changeSelectedOrganisationUnits,

    readExternalSystemsQuery,

    selectOption,
    submitSearch,
    clearFilters,

    monitorFiltersIsSuccess,
    monitorFilters,
    getMonitorFilter,
    loadMonitorFilters,
    selectMonitorFilter,
    isActiveMonitorFilter,

    table,
  };
};

export default useExternalSystemFilters;
