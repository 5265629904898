import { Button, Dialog, IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import { ReactElement, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import Scenario from "features/organisation/models/scenario";
import LinkableScenariosTable from "features/scenario/linkable-scenario/views/linkable-scenarios-table.component";
import "./organisation-details-linkable-scenarios-modal.component.scss";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onLinkButtonClicked: (selectedScenarios: Scenario[]) => void;
  scenariosToHide: Scenario[];
}

function OrganisationDetailsLinkableScenariosModal(
  props: Readonly<IProps>,
): ReactElement {
  const { t } = useTranslation("organisation");

  const [selectedScenarios, setSelectedScenarios] = useState<Scenario[]>([]);

  function handleOnClose() {
    props.onClose();
    setSelectedScenarios([]);
  }

  function handleOnLinkButtonClicked() {
    props.onLinkButtonClicked(selectedScenarios);
    setSelectedScenarios([]);
  }

  return (
    <Dialog
      className="organisation-details-linkable-scenarios-modal"
      open={props.isOpen}
      onClose={handleOnClose}
      PaperProps={{ classes: { root: "linkable-scenarios-modal-paper" } }}
    >
      <div className="linkable-scenarios-modal-container">
        <div className="linkable-scenarios-modal-header-container">
          <Typography className="header-title" variant="h3">
            {t("linkableScenariosModal.title")}
          </Typography>
          <div className="linkable-scenarios-modal-header-actions-container">
            {selectedScenarios.length < 1 && (
              <Button variant="contained" disabled={true}>
                {t("linkableScenariosModal.disabledLinkButtonText")}
              </Button>
            )}
            {selectedScenarios.length > 0 && (
              <Button
                className="link-button"
                variant="contained"
                onClick={handleOnLinkButtonClicked}
                startIcon={<CheckIcon className="check-icon" />}
                data-testid="linkSelectedScenariosButton"
              >
                {t("linkableScenariosModal.enabledLinkButtonText", {
                  amountOfSelectedScenarios: selectedScenarios.length,
                })}
              </Button>
            )}
            <IconButton
              aria-label="close"
              className="close-button"
              onClick={handleOnClose}
            >
              <Close />
            </IconButton>
          </div>
        </div>
        <LinkableScenariosTable
          onSelectedScenariosChanged={(scenarios) =>
            setSelectedScenarios(scenarios)
          }
          scenariosToHide={props.scenariosToHide}
        />
      </div>
    </Dialog>
  );
}

export default OrganisationDetailsLinkableScenariosModal;
