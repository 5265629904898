import Resident from "../domain/models/resident";
import { useEffect, useState } from "react";
import { FilterValueType } from "../domain/models/resident-settings-filter-value";
import ResidentSettingsFilter from "../domain/models/resident-settings-filter";
import useTableHook, { ITableHook } from "hooks/table-hook";
import SelectedOrganisationTreeNode from "features/organisation/domain/models/selected-organisation-tree-node";
import { useSessionStorage } from "usehooks-ts";
import ReadResidentsQuery from "../domain/models/read-residents-query";
import { useConfirmationPopupContextProvider } from "components/provided-confirmation-popup/context/confirmation-popup-provider";

export interface IResidentSettingsFiltersHook {
  activeFilters: ResidentSettingsFilter[];
  searchBarValue: string;
  changeSearchBarValue: (searchBarValue: string) => void;
  selectedOrganisationUnits: SelectedOrganisationTreeNode[];
  changeSelectedOrganisationUnits: (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) => void;

  selectOptions: (filterValueType: FilterValueType, keys: string[]) => void;
  clearFilters: () => void;

  readResidentSettingsQuery: ReadResidentsQuery;

  table: ITableHook<Resident>;
}

const useResidentSettingsFilters = (): IResidentSettingsFiltersHook => {
  const { showConfirmationPopup } = useConfirmationPopupContextProvider();

  const [readResidentSettingsQuery, setReadResidentSettingsQuery] =
    useState<ReadResidentsQuery>({
      filters: Array<ResidentSettingsFilter>(),
    } as ReadResidentsQuery);

  const table = useTableHook<Resident>({
    defaultSort: {
      isAscending: true,
      property: "organisationUnitName",
    },
    sessionStorageKey: "resident-setting-sort",
  });

  const [
    residentSettingsFiltersFromSessionStorage,
    setResidentSettingsFiltersFromSessionStorage,
  ] = useSessionStorage<ResidentSettingsFilter[]>(
    "resident-setting-filters",
    [],
  );
  const [
    selectedOrganisationUnitsFromSessionStorage,
    setSelectedOrganisationUnitsFromSessionStorage,
  ] = useSessionStorage<SelectedOrganisationTreeNode[]>(
    "selected-organisation-units-resident-setting-filters",
    [],
  );
  const [searchBarValueFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>("search-bar-value-resident-setting-filters", "");

  const [activeFilters, setActiveFilters] = useState<ResidentSettingsFilter[]>(
    residentSettingsFiltersFromSessionStorage,
  );

  useEffect(() => {
    buildAndSaveQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    table.currentPage,
    table.currentRowsPerPage,
    activeFilters,
    searchBarValueFromSessionStorage,
  ]);

  const changeSearchBarValue = (searchBarValue: string) => {
    setSearchBarFromSessionStorage(searchBarValue);
    table.resetPaging();
  };

  const selectOptions = (filterValueType: FilterValueType, keys: string[]) => {
    showConfirmationPopup(() => changeSelectedOptions(filterValueType, keys));
  };

  const changeSelectedOptions = (
    filterValueType: FilterValueType,
    keys: string[],
  ) => {
    let updatedFiltersList = [...readResidentSettingsQuery.filters];
    if (
      updatedFiltersList.findIndex(
        (x) => x.filterValueType === filterValueType,
      ) !== -1
    ) {
      updatedFiltersList[
        updatedFiltersList.findIndex(
          (x) => x.filterValueType === filterValueType,
        )
      ] = {
        filterValueType: filterValueType,
        values: keys,
      };
    } else {
      updatedFiltersList.push({
        filterValueType: filterValueType,
        values: keys,
      });
    }

    updatedFiltersList = updatedFiltersList.filter((x) => x.values.length > 0);

    setActiveFilters(updatedFiltersList);
    setResidentSettingsFiltersFromSessionStorage(updatedFiltersList);

    table.resetPaging();
  };

  const changeSelectedOrganisationUnits = (
    selectedOrganisationUnits: SelectedOrganisationTreeNode[],
  ) => {
    showConfirmationPopup(() => {
      setSelectedOrganisationUnitsFromSessionStorage(selectedOrganisationUnits);
      changeSelectedOptions(
        FilterValueType.OrganisationUnit,
        selectedOrganisationUnits.map((x) => x.id),
      );
    });
  };

  const clearFilters = () => {
    showConfirmationPopup(() => {
      setActiveFilters([]);
      setResidentSettingsFiltersFromSessionStorage([]);
      setSelectedOrganisationUnitsFromSessionStorage([]);
      setReadResidentSettingsQuery((prevState) => ({
        ...prevState,
        filters: [],
      }));

      table.resetPaging();
    });
  };

  function buildAndSaveQuery() {
    setReadResidentSettingsQuery((prevState) => ({
      ...prevState,
      pagination: table.currentPagination[0],
      filters: residentSettingsFiltersFromSessionStorage,
      searchQuery: searchBarValueFromSessionStorage,
    }));
  }

  return {
    activeFilters,
    searchBarValue: searchBarValueFromSessionStorage,
    changeSearchBarValue,
    selectedOrganisationUnits: selectedOrganisationUnitsFromSessionStorage,
    changeSelectedOrganisationUnits,

    readResidentSettingsQuery,

    selectOptions,
    clearFilters,

    table,
  };
};

export default useResidentSettingsFilters;
