import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ViewingMode } from "utils/viewing-utils";
import {
  useLazyAccessTenantQuery,
  useReadTenantsQuery,
  useUpdateTenantMutation,
} from "features/tenants/domain/reducers/tenant.reducer";
import ReadTenantsResponse from "features/tenants/domain/models/read-tenants-response";
import Tenant, {
  Monitoring,
  TenantMonitoringType,
} from "../domain/models/tenant";
import { FieldValues, UseFormReturn, useForm } from "react-hook-form";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import { useDispatch } from "react-redux";
import { showConfirmationPopup } from "features/confirmation-popup/domain/reducers/confirmation-popup.reducer";
import { useLogging } from "features/configuration/domain/providers/logging.provider";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAuth } from "features/authentication/providers/authentication.provider";
import TenantAccess from "../domain/models/tenant-access";
import {
  AggregatedType,
  AggregatedMonitoringStatus,
  AggregatedMonitoring,
} from "../domain/models/aggregated-monitor-data";
import UpdateTenantCommand from "../domain/models/update-tenant-command";

export interface ITenantsProviderHook {
  viewingMode: ViewingMode;
  readTenantsData: ReadTenantsResponse | undefined;
  readTenantsIsSuccess: boolean;
  readTenantsIsLoading: boolean;
  isTenantPopupOpen: boolean;
  currentTenant: Tenant | null;
  isUpdatingTenant: boolean;
  form: UseFormReturn<Tenant, any>;
  setViewingMode: Dispatch<SetStateAction<ViewingMode>>;
  openDetails: (viewingMode: ViewingMode, tenant: Tenant) => void;
  closeDetails: () => void;
  cancelEditDetails: () => void;
  submitTenant: (fieldValues: FieldValues) => void;
  onAccessTenant: (tenant: Tenant) => void;
  accessTenantData: TenantAccess | undefined;
  accessTenantIsLoading: boolean;
  aggregatedMonitoring: (
    monitoringItems: Monitoring[],
    type: AggregatedType,
  ) => AggregatedMonitoring[];
  allowOnAccessTenant: (tenant: Tenant) => boolean;
}

const useTenantsProvider = (): ITenantsProviderHook => {
  const dispatch = useDispatch();
  const { temporaryTenantAccess } = useAuth();
  const { log } = useLogging();
  const { tenant: authTenant } = useAuth();

  const [viewingMode, setViewingMode] = useState<ViewingMode>("none");
  const [isTenantPopupOpen, setIsTenantPopupOpen] = useState<boolean>(false);
  const [currentTenant, setCurrentTenant] = useState<Tenant | null>(null);
  const [isUpdatingTenant, setIsUpdatingTenant] = useState<boolean>(false);

  const form = useForm({
    defaultValues: {
      realmName: "",
      note: "",
    } as Tenant,
    mode: "onBlur",
  });

  const {
    formState: { isDirty },
    reset,
  } = form;

  const {
    data: readTenantsData,
    isSuccess: readTenantsIsSuccess,
    isLoading: readTenantsIsLoading,
    error: readTenantsError,
  } = useReadTenantsQuery();

  const [
    triggerAccessTenantQuery,
    {
      data: accessTenantData,
      isLoading: accessTenantIsLoading,
      error: accessTenantError,
    },
  ] = useLazyAccessTenantQuery();

  useEffect(() => {
    if (readTenantsError || accessTenantError) {
      const error = readTenantsError ?? accessTenantError;
      dispatch(setErrorMessage({ error }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readTenantsError, accessTenantError]);

  useEffect(() => {
    if (currentTenant && accessTenantData) {
      temporaryTenantAccess(accessTenantData, currentTenant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTenant, accessTenantData]);

  const onAccessTenant = (tenant: Tenant) => {
    log(`switching to tenant: ${tenant.realmName}`, SeverityLevel.Information);
    if (tenant && accessTenantData) {
      temporaryTenantAccess(accessTenantData, tenant);
    }

    sessionStorage.clear();
    triggerAccessTenantQuery(tenant.realmName);
  };

  const allowOnAccessTenant = (tenant: Tenant) => {
    return authTenant !== tenant.realmName;
  };

  const [
    updateTenant,
    { error: updateTenantError, isError: updateTenantIsError },
  ] = useUpdateTenantMutation();

  function openDetails(viewingMode: ViewingMode, tenant: Tenant) {
    reset(tenant);
    setViewingMode(viewingMode);
    setCurrentTenant(tenant);
    setIsTenantPopupOpen(true);
  }

  function cancelEditDetails() {
    setViewingMode("viewing");
    reset();
  }

  const closeDetails = () => {
    if (viewingMode === "editing" && isDirty) {
      dispatch(
        showConfirmationPopup({
          showInstantly: true,
          confirmActionNextAction: () => {
            setIsTenantPopupOpen(false);
            setViewingMode("viewing");
          },
        }),
      );
    } else {
      setIsTenantPopupOpen(false);
      setViewingMode("viewing");
    }
  };

  function submitTenant(fieldValues: FieldValues) {
    setIsUpdatingTenant(true);

    const tenant: UpdateTenantCommand = {
      realmName: fieldValues.realmName?.trim(),
      note: fieldValues.note?.trim(),
    };

    updateTenant(tenant);
    setViewingMode("viewing");
  }

  useEffect(() => {
    if (readTenantsData) {
      const tenant = readTenantsData.tenants.find(
        (tenant) => tenant.realmName === currentTenant?.realmName,
      );

      if (tenant) {
        setCurrentTenant(tenant);
        reset(tenant);
      }
    }

    setIsUpdatingTenant(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readTenantsData]);

  useEffect(() => {
    if (updateTenantIsError) {
      dispatch(
        setErrorMessage({
          error: updateTenantError,
        }),
      );
    }
    currentTenant && reset(currentTenant);
    setIsUpdatingTenant(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateTenantIsError]);

  const getTotalMonitoringCount = (items: Monitoring[]) =>
    items.reduce((acc, item) => acc + item.count, 0);

  const aggregatedMonitoring = (
    monitoringItems: Monitoring[],
    type: AggregatedType,
  ) => {
    const totalMonitoringCount = getTotalMonitoringCount(monitoringItems);
    if (totalMonitoringCount === 0) return [];

    switch (type) {
      case AggregatedType.ExternalSystems:
        return [
          {
            type: AggregatedMonitoringStatus.Red,
            count: getMonitoringCount(
              monitoringItems,
              TenantMonitoringType.Offline,
            ),
          },
          {
            type: AggregatedMonitoringStatus.Orange,
            count: getMonitoringCount(
              monitoringItems,
              TenantMonitoringType.PassiveExternalSystemOffline,
            ),
          },
          {
            type: AggregatedMonitoringStatus.Green,
            count: getMonitoringCount(
              monitoringItems,
              TenantMonitoringType.Online,
            ),
          },
        ];
      case AggregatedType.Devices:
        return [
          {
            type: AggregatedMonitoringStatus.Red,
            count: getMonitoringCount(
              monitoringItems,
              TenantMonitoringType.Offline,
              TenantMonitoringType.BatteryCritical,
            ),
          },
          {
            type: AggregatedMonitoringStatus.Orange,
            count: getMonitoringCount(
              monitoringItems,
              TenantMonitoringType.BatteryLow,
            ),
          },
          {
            type: AggregatedMonitoringStatus.Green,
            count: getMonitoringCount(
              monitoringItems,
              TenantMonitoringType.Online,
            ),
          },
          {
            type: AggregatedMonitoringStatus.Grey,
            count: getMonitoringCount(
              monitoringItems,
              TenantMonitoringType.NotMonitored,
            ),
          },
        ];
      default:
        return [];
    }
  };

  const getMonitoringCount = (
    items: Monitoring[],
    firstType: TenantMonitoringType,
    secondType?: TenantMonitoringType,
  ) => {
    let count = items.find((item) => item.type === firstType)?.count ?? 0;
    if (secondType) {
      count += items.find((item) => item.type === secondType)?.count ?? 0;
    }

    return count;
  };

  return {
    viewingMode,
    readTenantsData,
    readTenantsIsSuccess,
    readTenantsIsLoading,
    isTenantPopupOpen,
    currentTenant,
    isUpdatingTenant,
    form,
    setViewingMode,
    openDetails,
    closeDetails,
    cancelEditDetails,
    submitTenant,
    onAccessTenant,
    accessTenantData,
    accessTenantIsLoading,
    aggregatedMonitoring,
    allowOnAccessTenant,
  };
};

export default useTenantsProvider;
