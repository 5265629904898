import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "redux-base/create-api-utils";
import ReadLinkableDevicesCommand from "features/device/linkable-device/domain/models/read-linkable-devices-command";
import ReadLinkableDevicesResponse from "features/device/linkable-device/domain/models/read-linkable-devices-response";
import qs from "qs";
import ReadLinkableTransmittersQuery from "features/device/linkable-transmitters/domain/models/read-linkable-transmitters-query";
import ReadLinkableTransmittersResponse from "features/device/linkable-transmitters/domain/models/read-linkable-transmitters-response";

const baseUrl = "/api/v1/device";

export const linkableDeviceApi = createApi({
  reducerPath: "linkableDevice",
  baseQuery,
  tagTypes: ["LinkableDevice"],
  refetchOnMountOrArgChange: true,
  endpoints: (build) => ({
    readLinkableDevices: build.query<
      ReadLinkableDevicesResponse,
      ReadLinkableDevicesCommand
    >({
      query: (command) => ({
        url: `${baseUrl}/GetLinkableDevices`,
        method: "POST",
        body: command,
      }),
    }),
    readLinkableTransmitters: build.query<
      ReadLinkableTransmittersResponse,
      ReadLinkableTransmittersQuery
    >({
      query: (readLinkableTransmittersQuery) => ({
        url: `${baseUrl}/GetLinkableTransmitters?${qs.stringify(
          readLinkableTransmittersQuery,
          { allowDots: true },
        )}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyReadLinkableDevicesQuery,
  useLazyReadLinkableTransmittersQuery,
} = linkableDeviceApi;
