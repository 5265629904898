import {
  useGetOrganisationUnitTreeQuery,
  useGetMyOrganisationUnitTreeQuery,
  useLazyGetOrganisationUnitTreeForExternalSystemQuery,
  useGetOrganisationUnitTreeWithFaultsQuery,
} from "features/organisation/domain/reducers/organisation-unit.reducer";
import useOrganisationUnitTreeProvider, {
  IOrganisationUnitTreeProviderHook,
} from "features/organisation/hooks/organisation-unit-tree-hook";
import {
  ReactElement,
  ReactNode,
  createContext,
  useContext,
  useEffect,
} from "react";
import GetOrganisationUnitTreeQuery from "../domain/models/get-organisation-unit-tree-query";

const defaultEmptyValue = {} as IOrganisationUnitTreeProviderHook;
const OrganisationUnitTreeContext =
  createContext<IOrganisationUnitTreeProviderHook>(defaultEmptyValue);

type OrganisationUnitTreeProviderProps = {
  children: ReactNode;
  excludeBranchesWithoutResidents?: boolean;
};

type OrganisationUnitTreeProviderExternalSystemProps =
  OrganisationUnitTreeProviderProps & {
    externalSystemId?: string;
  };

const AllOrganisationUnitTreeProvider = (
  props: OrganisationUnitTreeProviderProps,
): ReactElement => {
  const getOrganistionUnitTreeQuery = {
    excludeBranchesWithoutResidents:
      props.excludeBranchesWithoutResidents ?? false,
  } as GetOrganisationUnitTreeQuery;
  const getOrganisationUnitTreeQuery = useGetOrganisationUnitTreeQuery(
    getOrganistionUnitTreeQuery,
  );

  const providerValue = useOrganisationUnitTreeProvider();

  useEffect(() => {
    providerValue.setOrganisationUnitTreeState?.({
      ...getOrganisationUnitTreeQuery,
      organisationUnitTree: getOrganisationUnitTreeQuery.data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrganisationUnitTreeQuery]);

  return (
    <OrganisationUnitTreeContext.Provider value={providerValue}>
      {props.children}
    </OrganisationUnitTreeContext.Provider>
  );
};

const MyOrganisationUnitTreeProvider = (
  props: OrganisationUnitTreeProviderProps,
): ReactElement => {
  const getOrganistionUnitTreeQuery = {
    excludeBranchesWithoutResidents:
      props.excludeBranchesWithoutResidents ?? false,
  } as GetOrganisationUnitTreeQuery;
  const getMyOrganisationUnitTreeQuery = useGetMyOrganisationUnitTreeQuery(
    getOrganistionUnitTreeQuery,
  );
  const providerValue = useOrganisationUnitTreeProvider();

  useEffect(() => {
    providerValue.setOrganisationUnitTreeState?.({
      ...getMyOrganisationUnitTreeQuery,
      organisationUnitTree: getMyOrganisationUnitTreeQuery.data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMyOrganisationUnitTreeQuery]);

  return (
    <OrganisationUnitTreeContext.Provider value={providerValue}>
      {props.children}
    </OrganisationUnitTreeContext.Provider>
  );
};

const OrganisationUnitTreeForExternalSystemProvider = (
  props: OrganisationUnitTreeProviderExternalSystemProps,
): ReactElement => {
  const [triggerGetOrganisationUnitTree, getOrganisationUnitTreeQuery] =
    useLazyGetOrganisationUnitTreeForExternalSystemQuery();
  const providerValue = useOrganisationUnitTreeProvider();

  useEffect(() => {
    if (props.externalSystemId) {
      triggerGetOrganisationUnitTree(props.externalSystemId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.externalSystemId]);

  useEffect(() => {
    providerValue.setOrganisationUnitTreeState?.({
      ...getOrganisationUnitTreeQuery,
      organisationUnitTree: getOrganisationUnitTreeQuery.data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrganisationUnitTreeQuery]);

  return (
    <OrganisationUnitTreeContext.Provider value={providerValue}>
      {props.children}
    </OrganisationUnitTreeContext.Provider>
  );
};

const OrganisationUnitTreeWithFaultsProvider = (
  props: OrganisationUnitTreeProviderProps,
): ReactElement => {
  const getOrganisationUnitTreeQuery =
    useGetOrganisationUnitTreeWithFaultsQuery();
  const providerValue = useOrganisationUnitTreeProvider();

  useEffect(() => {
    providerValue.setOrganisationUnitTreeState?.({
      ...getOrganisationUnitTreeQuery,
      organisationUnitTree: getOrganisationUnitTreeQuery.data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOrganisationUnitTreeQuery]);

  return (
    <OrganisationUnitTreeContext.Provider value={providerValue}>
      {props.children}
    </OrganisationUnitTreeContext.Provider>
  );
};

const useOrganisationUnitTreeContext =
  (): IOrganisationUnitTreeProviderHook => {
    const context = useContext<IOrganisationUnitTreeProviderHook>(
      OrganisationUnitTreeContext,
    );

    if (context === defaultEmptyValue) {
      throw new Error(
        "useOrganisationUnitTreeContext must be used within one of the OrganisationUnitTreeContextProviders",
      );
    }

    return context;
  };

const OrganisationUnitTreeProvider = {
  All: AllOrganisationUnitTreeProvider,
  AccessRightsOnly: MyOrganisationUnitTreeProvider,
  ForExternalSystem: OrganisationUnitTreeForExternalSystemProvider,
  WithFaults: OrganisationUnitTreeWithFaultsProvider,
};

export { OrganisationUnitTreeProvider, useOrganisationUnitTreeContext };
