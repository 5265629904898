import LoadingIndicator from "components/loading-indicator/loading-indicator.component";
import { ColumnDefinition } from "components/table/columnDefinition";
import Table, { IRowItem } from "components/table/table";
import LinkableDevice from "features/device/linkable-device/domain/models/linkable-device";
import { useLazyReadLinkableTransmittersQuery } from "features/device/linkable-device/domain/reducers/linkable-device.reducer";
import { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NestedKeyof } from "utils/nested-keyof-utils";
import Pagination from "models/pagination";
import "./linkable-transmitters-table.component.scss";
import { useAppDispatch } from "redux-base/store";
import { setErrorMessage } from "features/error-handling/domain/reducers/error-handling.reducer";
import ReadLinkableTransmittersQuery from "features/device/linkable-transmitters/domain/models/read-linkable-transmitters-query";
import DeviceFilters from "features/device/views/device-filters";
import useTableHook from "hooks/table-hook";
import { useSessionStorage } from "usehooks-ts";
import ObjectSort from "models/object-sort";
import MonitoringIcon from "components/monitoring/monitoring-icon.component";
import { MonitoringType } from "components/monitoring/monitoring-type";
import { toMonitoringType } from "models/fault-type";

interface IProps {
  organisationUnitId: string;
  onSelectedDevicesChanged: (devices: LinkableDevice[]) => void;
  devicesToHide: LinkableDevice[];
}

function LinkableTransmittersTable(props: Readonly<IProps>): ReactElement {
  const { t } = useTranslation("linkableDevice");
  const dispatch = useAppDispatch();
  const sessionStorageSortingKey = "linkable-transmitters-sorting";
  const searchBarSessionStorageKey = "linkable-transmitters-searchbar";

  const renderIcon = (device: LinkableDevice): ReactElement => {
    const monitoringType: MonitoringType | undefined = toMonitoringType(
      device.fault,
    );

    return monitoringType ? (
      <MonitoringIcon type={monitoringType} withTooltip visible size={16} />
    ) : (
      <></>
    );
  };

  const columns: ColumnDefinition<
    LinkableDevice,
    NestedKeyof<LinkableDevice>
  >[] = [
    {
      key: "name",
      label: t("linkableDevicesTable.column.name"),
    },
    {
      key: "fault",
      label: "",
      renderCustomContentProvider: renderIcon,
      disableSort: true,
      tableCellProps: {
        align: "left",
        width: "40px",
      },
    },
  ];

  const [sortFromSessionStorage, setSortFromSessionStorage] = useSessionStorage<
    ObjectSort<LinkableDevice>
  >(sessionStorageSortingKey, { property: "name", isAscending: true });
  const [searchBarFromSessionStorage, setSearchBarFromSessionStorage] =
    useSessionStorage<string>(searchBarSessionStorageKey, "");

  const {
    currentPagination: [currentPagination, setCurrentPagination],
    currentSearchQuery: [currentSearchQuery],
    currentPage,
    currentRowsPerPage,
    setCurrentPage,
    handleOnPageChanged,
    handleOnRowsPerPageChanged,
    handleOnSearchChanged,
  } = useTableHook();

  const [
    triggerReadLinkableTransmittersQuery,
    {
      data: readLinkableDevicesData,
      isSuccess: readLinkableDevicesIsSuccess,
      isLoading: readLinkableDevicesIsLoading,
      error: readLinkableDevicesError,
    },
  ] = useLazyReadLinkableTransmittersQuery();

  const hiddenAndLiveDataMergedList =
    readLinkableDevicesData?.devices?.filter(
      (device) =>
        props.devicesToHide.findIndex(
          (deviceToHide) => deviceToHide.id === device.id,
        ) === -1,
    ) ?? [];

  function handleOnSortChanged(
    property: NestedKeyof<LinkableDevice>,
    isAscending: boolean,
  ) {
    setSortFromSessionStorage({
      isAscending: isAscending,
      property: property,
    });
    setCurrentPage(0);
    setCurrentPagination({
      skip: 0,
      take: currentRowsPerPage,
    });
  }

  useEffect(() => {
    if (readLinkableDevicesError) {
      dispatch(
        setErrorMessage({
          error: readLinkableDevicesError,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readLinkableDevicesError]);

  useEffect(() => {
    const paginationWithHiddenDevices = {
      skip: currentPagination.skip,
      take: currentPagination.take + props.devicesToHide.length,
    } as Pagination;

    const readDevicesQuery = {
      organisationUnitId: props.organisationUnitId,
      pagination: paginationWithHiddenDevices,
      sort: sortFromSessionStorage,
      searchQuery: searchBarFromSessionStorage,
    } as ReadLinkableTransmittersQuery;

    triggerReadLinkableTransmittersQuery(readDevicesQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortFromSessionStorage, currentPagination, currentSearchQuery]);

  return (
    <>
      {readLinkableDevicesIsLoading && <LoadingIndicator />}
      {readLinkableDevicesIsSuccess && readLinkableDevicesData && (
        <>
          <DeviceFilters
            onSearchValueChanged={(value: string) => {
              handleOnSearchChanged(value);
              setSearchBarFromSessionStorage(value);
            }}
            activeFilters={[]}
            filterValues={[]}
            monitoringValues={[]}
            activeMonitoringFilters={[]}
            onOptionsSelected={() => {}}
            onMonitoringFilterSelected={() => {}}
            clearFilters={() => {}}
            searchBarValue={searchBarFromSessionStorage}
          />
          <Table
            className="linkable-devices-table"
            data={hiddenAndLiveDataMergedList.map<IRowItem<LinkableDevice>>(
              (linkableDevice) => ({ data: linkableDevice }),
            )}
            columns={columns}
            rowsAreSelectable={true}
            onSelectedRowsChanged={(devices: LinkableDevice[]) =>
              props.onSelectedDevicesChanged(devices)
            }
            enablePagination={true}
            count={readLinkableDevicesData.total}
            onPageChanged={handleOnPageChanged}
            rowsPerPage={currentRowsPerPage}
            onSortChanged={handleOnSortChanged}
            page={currentPage}
            onRowsPerPageChanged={handleOnRowsPerPageChanged}
            rowIdentifier={(item: LinkableDevice) => item.id}
            initialOrderBy={sortFromSessionStorage.property}
            initialOrderDirection={
              sortFromSessionStorage.isAscending ? "asc" : "desc"
            }
          />
        </>
      )}
    </>
  );
}

export default LinkableTransmittersTable;
