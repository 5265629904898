import { useTranslation } from "react-i18next";
import { useExternalSystemsContextProvider } from "../context/external-systems-provider";

export interface IExternalSystemValidationHook {
  validateName: (name: string) => boolean | string;
  validateIpAddress: (ipAddress: string) => boolean | string;
  validateVirtualIpAddress: (ipAddress: string) => boolean | string;
  validatePassword: (password: string) => boolean | string;
}

const useExternalSystemValidation = (): IExternalSystemValidationHook => {
  const ipAddressValidationRegex =
    /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;
  const passwordValidationRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z]).{8,}$/;
  const { t } = useTranslation("externalSystem");

  const { externalSystemDetailsHook: detailView, externalSystemsResponse } =
    useExternalSystemsContextProvider();

  const validateName = (name: string) => {
    name = name.trim();
    if (
      detailView.currentSelectedExternalSystem?.name === name ||
      !externalSystemsResponse?.externalSystems?.some(
        (system) => system.name === name,
      )
    ) {
      return true;
    }

    return t("details.information.uniqueNameHelperText");
  };

  const validateVirtualIpAddress = (ipAddress: string) => {
    ipAddress = ipAddress.trim();
    if (!ipAddress) {
      return t("details.information.requiredHelperText");
    }

    if (!ipAddressValidationRegex.test(ipAddress)) {
      return t("details.information.invalidVirtualIpAddressHelperText");
    }

    if (
      detailView.currentSelectedExternalSystem?.ipAddress !== ipAddress &&
      externalSystemsResponse?.externalSystems?.some(
        (system) => system.ipAddress === ipAddress,
      )
    ) {
      return t("details.information.duplicateIpAddressHelperText");
    }

    return true;
  };

  const validateIpAddress = (ipAddress: string | undefined) => {
    ipAddress = ipAddress?.trim();
    if (!ipAddress) {
      return true;
    }

    if (!ipAddressValidationRegex.test(ipAddress)) {
      return t("details.information.invalidIpAddressHelperText");
    }

    return true;
  };

  const validatePassword = (password: string) => {
    if (!password) {
      return t("details.information.requiredHelperText");
    }

    if (!passwordValidationRegex.test(password)) {
      return t(
        "details.settings.offlinePortalCredentials.invalidPasswordHelperText",
      );
    }

    return true;
  };

  return {
    validateName,
    validateIpAddress,
    validateVirtualIpAddress,
    validatePassword,
  };
};

export default useExternalSystemValidation;
